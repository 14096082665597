/* FeedbackModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above other content */
}

.modal-content {
  background: white;
  padding: 25px;
  border-radius: 5px;
  width: 80%;
  max-width: 670px;
  z-index: 1001; /* Ensure it is above the overlay */
}

.modal-width-40 {
  width: 40% !important;
}

.modal-width-30 {
  width: 30%;
}

.modal-close-button {
  background: rgb(255, 255, 255);
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
  float: right;
}

.modal-content-pw-info {
  max-width: 80%;
}

.modal-width {
  width: 40% !important;
}

.modal-close-button {
  background: rgb(255, 255, 255);
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
  float: right;
}

.no-scroll {
  overflow: hidden;
}

.radioButtonLabel {
  display: flex;
}

.margin-bottom {
  li {
    margin-bottom: 5px;
  }
}

ol {
  margin-bottom: 10px;
}

.margin-top-large {
  margin-top: 20px;
}