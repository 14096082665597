/**
 * Do not edit directly
 * Generated on Thu, 02 May 2024 18:59:07 GMT
 */
:root {
    --muse-token-set-order-0: muse-core;
    --muse-background-information-weak: #f2f8fd;
    --muse-background-warning-weak: #fdf8f2;
    --muse-background-success-weak: #f3f9f2;
    --muse-background-error-weak: #fdf4f4;
    --muse-color-light-yellow-10: #fdf8f2;
    --muse-color-light-green-10: #f3f9f2;
    --muse-color-light-red-10: #fdf4f4;
    --muse-color-light-blue-10: #f2f8fd;
    --muse-color-red-20: #db231a;
    --muse-color-red-10: #ff4c44;
    --muse-color-purple-20: #551a8b;
    --muse-color-purple-10: #b279e6;
    --muse-color-orange-20: #d27700;
    --muse-color-orange-10: #fbab2c;
    --muse-color-green-20: #058103;
    --muse-color-green-10: #74bf4b;
    --muse-color-white-alpha-85: rgba(255, 255, 255, 0.85);
    --muse-color-white-alpha-50: rgba(255, 255, 255, 0.5);
    --muse-color-white-alpha-25: rgba(255, 255, 255, 0.25);
    --muse-color-white-alpha-15: rgba(255, 255, 255, 0.15);
    --muse-color-white: #ffffff;
    --muse-color-gray-60: #1b1c1d;
    --muse-color-gray-50: #606162;
    --muse-color-gray-40: #8e8e8e;
    --muse-color-gray-30: #d6d6d6;
    --muse-color-gray-20: #ebebeb;
    --muse-color-gray-10: #f4f5f6;
    --muse-color-blue-50: #07162c;
    --muse-color-blue-40: #0d274d;
    --muse-color-blue-30: #0060ad;
    --muse-color-blue-20: #0070d2;
    --muse-color-blue-10: #008dff;
    --muse-color-brand: #00bceb;
    --muse-grid-total-columns: 12;
    --muse-breakpoint-lg: 1440px;
    --muse-breakpoint-md: 1024px;
    --muse-breakpoint-sm: 600px;
    --muse-breakpoint-xs: 320px;
    --muse-container-lg: 1340px;
    --muse-container-md: 1200px;
    --muse-container-sm: 750px;
    --muse-container-xs: 450px;
    --muse-letter-spacing-wide: 0.2;
    --muse-letter-spacing-regular: 0;
    --muse-letter-spacing-tight: -1;
    --muse-line-height-90: 77px;
    --muse-line-height-80: 62px;
    --muse-line-height-70: 48px;
    --muse-line-height-60: 40px;
    --muse-line-height-50: 32px;
    --muse-line-height-40: 28px;
    --muse-line-height-30: 24px;
    --muse-line-height-20: 20px;
    --muse-line-height-10: 16px;
    --muse-font-size-90: 6.4rem;
    --muse-font-size-80: 5.2rem;
    --muse-font-size-70: 4rem;
    --muse-font-size-60: 3.2rem;
    --muse-font-size-50: 2.4rem;
    --muse-font-size-40: 2rem;
    --muse-font-size-30: 1.6rem;
    --muse-font-size-20: 1.4rem;
    --muse-font-size-10: 1.2rem;
    --muse-font-weight-700: 700;
    --muse-font-weight-600: 600;
    --muse-font-weight-500: 500;
    --muse-font-weight-400: 400;
    --muse-font-weight-300: 300;
    --muse-font-weight-200: 200;
    --muse-font-weight-100: 100;
    --muse-font-family-default: "CiscoSans", "CiscoSansTT", sans-serif;
    --muse-z-index-top: 9999;
    --muse-z-index-100: 10;
    --muse-z-index-90: 9;
    --muse-z-index-80: 8;
    --muse-z-index-70: 7;
    --muse-z-index-60: 6;
    --muse-z-index-50: 5;
    --muse-z-index-40: 4;
    --muse-z-index-30: 3;
    --muse-z-index-20: 2;
    --muse-z-index-10: 1;
    --muse-elevation-high: 0px 12px 48px 0px #1b1c1d1a;
    --muse-elevation-default: 0px 2px 16px 0px #1b1c1d14;
    --muse-radius-circle: 100%;
    --muse-radius-50: 48px;
    --muse-radius-40: 24px;
    --muse-radius-30: 8px;
    --muse-radius-20: 4px;
    --muse-radius-10: 2px;
    --muse-radius-0: 0px;
    --muse-border-width-thick: 2px;
    --muse-border-width-regular: 1px;
    --muse-space-170: 12rem;
    --muse-space-160: 8rem;
    --muse-space-150: 7.2rem;
    --muse-space-140: 6.4rem;
    --muse-space-130: 5.6rem;
    --muse-space-120: 4.8rem;
    --muse-space-110: 4rem;
    --muse-space-100: 3.6rem;
    --muse-space-90: 3.2rem;
    --muse-space-80: 2.8rem;
    --muse-space-70: 2.4rem;
    --muse-space-60: 2rem;
    --muse-space-50: 1.6rem;
    --muse-space-40: 1.2rem;
    --muse-space-30: 0.8rem;
    --muse-space-20: 0.4rem;
    --muse-space-10: 0.2rem;
    --muse-space-0: 0;
    --muse-size-170: 12rem;
    --muse-size-160: 8rem;
    --muse-size-150: 7.2rem;
    --muse-size-140: 6.4rem;
    --muse-size-130: 5.6rem;
    --muse-size-120: 4.8rem;
    --muse-size-110: 4rem;
    --muse-size-100: 3.6rem;
    --muse-size-90: 3.2rem;
    --muse-size-80: 2.8rem;
    --muse-size-70: 2.4rem;
    --muse-size-60: 2rem;
    --muse-size-50: 1.6rem;
    --muse-size-40: 1.2rem;
    --muse-size-30: 0.8rem;
    --muse-size-20: 0.4rem;
    --muse-size-10: 0.2rem;
    --muse-size-0: 0;
    --muse-root-rem-size: 10;
    --muse-current-version: 2.5.4-beta;
    --muse-text-success-lighter: var(--muse-color-green-10);
    --muse-text-success: var(--muse-color-green-20);
    --muse-text-error-lighter: var(--muse-color-red-10);
    --muse-text-error: var(--muse-color-red-20);
    --muse-text-link-visited-lighter: var(--muse-color-purple-10);
    --muse-text-link-visited: var(--muse-color-purple-20);
    --muse-text-inverse-weak: var(--muse-color-white-alpha-50);
    --muse-text-inverse-darker: var(--muse-color-gray-10);
    --muse-text-inverse: var(--muse-color-white);
    --muse-text-default-lightest: var(--muse-color-gray-40);
    --muse-text-default-lighter: var(--muse-color-gray-50);
    --muse-text-default: var(--muse-color-gray-60);
    --muse-text-emphasis-darker: var(--muse-color-blue-50);
    --muse-text-emphasis: var(--muse-color-blue-40);
    --muse-text-accent-darker: var(--muse-color-blue-30);
    --muse-text-accent-lighter: var(--muse-color-blue-10);
    --muse-text-accent: var(--muse-color-blue-20);
    --muse-border-warning-lighter: var(--muse-color-orange-10);
    --muse-border-warning: var(--muse-color-orange-20);
    --muse-border-success-light: var(--muse-color-green-10);
    --muse-border-success: var(--muse-color-green-20);
    --muse-border-error-light: var(--muse-color-red-10);
    --muse-border-error: var(--muse-color-red-20);
    --muse-border-inverse-weakest: var(--muse-color-white-alpha-25);
    --muse-border-inverse-weak: var(--muse-color-white-alpha-50);
    --muse-border-inverse-darker: var(--muse-color-gray-20);
    --muse-border-inverse: var(--muse-color-white);
    --muse-border-default-darker: var(--muse-color-gray-40);
    --muse-border-default: var(--muse-color-gray-30);
    --muse-border-emphasis-darker: var(--muse-color-blue-50);
    --muse-border-emphasis: var(--muse-color-blue-40);
    --muse-border-accent-darker: var(--muse-color-blue-30);
    --muse-border-accent-lighter: var(--muse-color-blue-10);
    --muse-border-accent: var(--muse-color-blue-20);
    --muse-background-warning-lighter: var(--muse-color-orange-10);
    --muse-background-warning: var(--muse-color-orange-20);
    --muse-background-success-lighter: var(--muse-color-green-10);
    --muse-background-success: var(--muse-color-green-20);
    --muse-background-error-lighter: var(--muse-color-red-10);
    --muse-background-error: var(--muse-color-red-20);
    --muse-background-inverse-weakest: var(--muse-color-white-alpha-25);
    --muse-background-inverse-weak: var(--muse-color-white-alpha-50);
    --muse-background-neutral-darkest: var(--muse-color-gray-40);
    --muse-background-neutral-darker-2: var(--muse-color-gray-30);
    --muse-background-neutral-darker: var(--muse-color-gray-20);
    --muse-background-neutral-dark: var(--muse-color-gray-10);
    --muse-background-neutral: var(--muse-color-white);
    --muse-background-emphasis-darker: var(--muse-color-blue-50);
    --muse-background-emphasis: var(--muse-color-blue-40);
    --muse-background-accent-lighter: var(--muse-color-blue-10);
    --muse-background-accent-darker: var(--muse-color-blue-30);
    --muse-background-accent: var(--muse-color-blue-20);
    --muse-grid-gap-normal: var(--muse-size-70);
    --muse-grid-gap-tight: var(--muse-space-60);
    --muse-grid-margin-lg: var(--muse-size-170);
    --muse-grid-margin-md: var(--muse-size-160);
    --muse-grid-margin-sm: var(--muse-size-120);
    --muse-grid-margin-xs: var(--muse-size-50);
}

/* stylelint-disable */
/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Prevent font size inflation */
html {
    text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin-block-end: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
    list-style: none;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
    line-height: 1.1;
}

/* Balance text wrapping on headings */
h1,
h2,
h3,
h4 {
    text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
    color: currentColor;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
    min-height: 5em;
    width: 33em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
    scroll-margin-block: 5ex;
}

.muse-h-0 {
    height: var(--muse-size-0, 0);
}

.muse-min-h-0 {
    min-height: var(--muse-size-0, 0);
}

.muse-max-h-0 {
    max-height: var(--muse-size-0, 0);
}

.muse-w-0 {
    height: var(--muse-size-0, 0);
}

.muse-min-w-0 {
    min-width: var(--muse-size-0, 0);
}

.muse-max-w-0 {
    max-width: var(--muse-size-0, 0);
}

.muse-size-0 {
    height: var(--muse-size-0, 0);
    width: var(--muse-size-0, 0);
}

.muse-h-10 {
    height: var(--muse-size-10, 0.2rem);
}

.muse-min-h-10 {
    min-height: var(--muse-size-10, 0.2rem);
}

.muse-max-h-10 {
    max-height: var(--muse-size-10, 0.2rem);
}

.muse-w-10 {
    height: var(--muse-size-10, 0.2rem);
}

.muse-min-w-10 {
    min-width: var(--muse-size-10, 0.2rem);
}

.muse-max-w-10 {
    max-width: var(--muse-size-10, 0.2rem);
}

.muse-size-10 {
    height: var(--muse-size-10, 0.2rem);
    width: var(--muse-size-10, 0.2rem);
}

.muse-h-20 {
    height: var(--muse-size-20, 0.4rem);
}

.muse-min-h-20 {
    min-height: var(--muse-size-20, 0.4rem);
}

.muse-max-h-20 {
    max-height: var(--muse-size-20, 0.4rem);
}

.muse-w-20 {
    height: var(--muse-size-20, 0.4rem);
}

.muse-min-w-20 {
    min-width: var(--muse-size-20, 0.4rem);
}

.muse-max-w-20 {
    max-width: var(--muse-size-20, 0.4rem);
}

.muse-size-20 {
    height: var(--muse-size-20, 0.4rem);
    width: var(--muse-size-20, 0.4rem);
}

.muse-h-30 {
    height: var(--muse-size-30, 0.8rem);
}

.muse-min-h-30 {
    min-height: var(--muse-size-30, 0.8rem);
}

.muse-max-h-30 {
    max-height: var(--muse-size-30, 0.8rem);
}

.muse-w-30 {
    height: var(--muse-size-30, 0.8rem);
}

.muse-min-w-30 {
    min-width: var(--muse-size-30, 0.8rem);
}

.muse-max-w-30 {
    max-width: var(--muse-size-30, 0.8rem);
}

.muse-size-30 {
    height: var(--muse-size-30, 0.8rem);
    width: var(--muse-size-30, 0.8rem);
}

.muse-h-40 {
    height: var(--muse-size-40, 1.2rem);
}

.muse-min-h-40 {
    min-height: var(--muse-size-40, 1.2rem);
}

.muse-max-h-40 {
    max-height: var(--muse-size-40, 1.2rem);
}

.muse-w-40 {
    height: var(--muse-size-40, 1.2rem);
}

.muse-min-w-40 {
    min-width: var(--muse-size-40, 1.2rem);
}

.muse-max-w-40 {
    max-width: var(--muse-size-40, 1.2rem);
}

.muse-size-40 {
    height: var(--muse-size-40, 1.2rem);
    width: var(--muse-size-40, 1.2rem);
}

.muse-h-50 {
    height: var(--muse-size-50, 1.6rem);
}

.muse-min-h-50 {
    min-height: var(--muse-size-50, 1.6rem);
}

.muse-max-h-50 {
    max-height: var(--muse-size-50, 1.6rem);
}

.muse-w-50 {
    height: var(--muse-size-50, 1.6rem);
}

.muse-min-w-50 {
    min-width: var(--muse-size-50, 1.6rem);
}

.muse-max-w-50 {
    max-width: var(--muse-size-50, 1.6rem);
}

.muse-size-50 {
    height: var(--muse-size-50, 1.6rem);
    width: var(--muse-size-50, 1.6rem);
}

.muse-h-60 {
    height: var(--muse-size-60, 2rem);
}

.muse-min-h-60 {
    min-height: var(--muse-size-60, 2rem);
}

.muse-max-h-60 {
    max-height: var(--muse-size-60, 2rem);
}

.muse-w-60 {
    height: var(--muse-size-60, 2rem);
}

.muse-min-w-60 {
    min-width: var(--muse-size-60, 2rem);
}

.muse-max-w-60 {
    max-width: var(--muse-size-60, 2rem);
}

.muse-size-60 {
    height: var(--muse-size-60, 2rem);
    width: var(--muse-size-60, 2rem);
}

.muse-h-70 {
    height: var(--muse-size-70, 2.4rem);
}

.muse-min-h-70 {
    min-height: var(--muse-size-70, 2.4rem);
}

.muse-max-h-70 {
    max-height: var(--muse-size-70, 2.4rem);
}

.muse-w-70 {
    height: var(--muse-size-70, 2.4rem);
}

.muse-min-w-70 {
    min-width: var(--muse-size-70, 2.4rem);
}

.muse-max-w-70 {
    max-width: var(--muse-size-70, 2.4rem);
}

.muse-size-70 {
    height: var(--muse-size-70, 2.4rem);
    width: var(--muse-size-70, 2.4rem);
}

.muse-h-80 {
    height: var(--muse-size-80, 2.8rem);
}

.muse-min-h-80 {
    min-height: var(--muse-size-80, 2.8rem);
}

.muse-max-h-80 {
    max-height: var(--muse-size-80, 2.8rem);
}

.muse-w-80 {
    height: var(--muse-size-80, 2.8rem);
}

.muse-min-w-80 {
    min-width: var(--muse-size-80, 2.8rem);
}

.muse-max-w-80 {
    max-width: var(--muse-size-80, 2.8rem);
}

.muse-size-80 {
    height: var(--muse-size-80, 2.8rem);
    width: var(--muse-size-80, 2.8rem);
}

.muse-h-90 {
    height: var(--muse-size-90, 3.2rem);
}

.muse-min-h-90 {
    min-height: var(--muse-size-90, 3.2rem);
}

.muse-max-h-90 {
    max-height: var(--muse-size-90, 3.2rem);
}

.muse-w-90 {
    height: var(--muse-size-90, 3.2rem);
}

.muse-min-w-90 {
    min-width: var(--muse-size-90, 3.2rem);
}

.muse-max-w-90 {
    max-width: var(--muse-size-90, 3.2rem);
}

.muse-size-90 {
    height: var(--muse-size-90, 3.2rem);
    width: var(--muse-size-90, 3.2rem);
}

.muse-h-100 {
    height: var(--muse-size-100, 3.6rem);
}

.muse-min-h-100 {
    min-height: var(--muse-size-100, 3.6rem);
}

.muse-max-h-100 {
    max-height: var(--muse-size-100, 3.6rem);
}

.muse-w-100 {
    height: var(--muse-size-100, 3.6rem);
}

.muse-min-w-100 {
    min-width: var(--muse-size-100, 3.6rem);
}

.muse-max-w-100 {
    max-width: var(--muse-size-100, 3.6rem);
}

.muse-size-100 {
    height: var(--muse-size-100, 3.6rem);
    width: var(--muse-size-100, 3.6rem);
}

.muse-h-110 {
    height: var(--muse-size-110, 4rem);
}

.muse-min-h-110 {
    min-height: var(--muse-size-110, 4rem);
}

.muse-max-h-110 {
    max-height: var(--muse-size-110, 4rem);
}

.muse-w-110 {
    height: var(--muse-size-110, 4rem);
}

.muse-min-w-110 {
    min-width: var(--muse-size-110, 4rem);
}

.muse-max-w-110 {
    max-width: var(--muse-size-110, 4rem);
}

.muse-size-110 {
    height: var(--muse-size-110, 4rem);
    width: var(--muse-size-110, 4rem);
}

.muse-h-120 {
    height: var(--muse-size-120, 4.8rem);
}

.muse-min-h-120 {
    min-height: var(--muse-size-120, 4.8rem);
}

.muse-max-h-120 {
    max-height: var(--muse-size-120, 4.8rem);
}

.muse-w-120 {
    height: var(--muse-size-120, 4.8rem);
}

.muse-min-w-120 {
    min-width: var(--muse-size-120, 4.8rem);
}

.muse-max-w-120 {
    max-width: var(--muse-size-120, 4.8rem);
}

.muse-size-120 {
    height: var(--muse-size-120, 4.8rem);
    width: var(--muse-size-120, 4.8rem);
}

.muse-h-130 {
    height: var(--muse-size-130, 5.6rem);
}

.muse-min-h-130 {
    min-height: var(--muse-size-130, 5.6rem);
}

.muse-max-h-130 {
    max-height: var(--muse-size-130, 5.6rem);
}

.muse-w-130 {
    height: var(--muse-size-130, 5.6rem);
}

.muse-min-w-130 {
    min-width: var(--muse-size-130, 5.6rem);
}

.muse-max-w-130 {
    max-width: var(--muse-size-130, 5.6rem);
}

.muse-size-130 {
    height: var(--muse-size-130, 5.6rem);
    width: var(--muse-size-130, 5.6rem);
}

.muse-h-140 {
    height: var(--muse-size-140, 6.4rem);
}

.muse-min-h-140 {
    min-height: var(--muse-size-140, 6.4rem);
}

.muse-max-h-140 {
    max-height: var(--muse-size-140, 6.4rem);
}

.muse-w-140 {
    height: var(--muse-size-140, 6.4rem);
}

.muse-min-w-140 {
    min-width: var(--muse-size-140, 6.4rem);
}

.muse-max-w-140 {
    max-width: var(--muse-size-140, 6.4rem);
}

.muse-size-140 {
    height: var(--muse-size-140, 6.4rem);
    width: var(--muse-size-140, 6.4rem);
}

.muse-h-150 {
    height: var(--muse-size-150, 7.2rem);
}

.muse-min-h-150 {
    min-height: var(--muse-size-150, 7.2rem);
}

.muse-max-h-150 {
    max-height: var(--muse-size-150, 7.2rem);
}

.muse-w-150 {
    height: var(--muse-size-150, 7.2rem);
}

.muse-min-w-150 {
    min-width: var(--muse-size-150, 7.2rem);
}

.muse-max-w-150 {
    max-width: var(--muse-size-150, 7.2rem);
}

.muse-size-150 {
    height: var(--muse-size-150, 7.2rem);
    width: var(--muse-size-150, 7.2rem);
}

.muse-h-160 {
    height: var(--muse-size-160, 8rem);
}

.muse-min-h-160 {
    min-height: var(--muse-size-160, 8rem);
}

.muse-max-h-160 {
    max-height: var(--muse-size-160, 8rem);
}

.muse-w-160 {
    height: var(--muse-size-160, 8rem);
}

.muse-min-w-160 {
    min-width: var(--muse-size-160, 8rem);
}

.muse-max-w-160 {
    max-width: var(--muse-size-160, 8rem);
}

.muse-size-160 {
    height: var(--muse-size-160, 8rem);
    width: var(--muse-size-160, 8rem);
}

.muse-h-170 {
    height: var(--muse-size-170, 12rem);
}

.muse-min-h-170 {
    min-height: var(--muse-size-170, 12rem);
}

.muse-max-h-170 {
    max-height: var(--muse-size-170, 12rem);
}

.muse-w-170 {
    height: var(--muse-size-170, 12rem);
}

.muse-min-w-170 {
    min-width: var(--muse-size-170, 12rem);
}

.muse-max-w-170 {
    max-width: var(--muse-size-170, 12rem);
}

.muse-size-170 {
    height: var(--muse-size-170, 12rem);
    width: var(--muse-size-170, 12rem);
}

.muse-p-0 {
    padding: var(--muse-space-0, 0);
}

.muse-pr-0 {
    padding-right: var(--muse-space-0, 0);
}

.muse-pb-0 {
    padding-bottom: var(--muse-space-0, 0);
}

.muse-pl-0 {
    padding-left: var(--muse-space-0, 0);
}

.muse-pt-0 {
    padding-top: var(--muse-space-0, 0);
}

.muse-py-0 {
    padding-bottom: var(--muse-space-0, 0);
    padding-top: var(--muse-space-0, 0);
}

.muse-px-0 {
    padding-left: var(--muse-space-0, 0);
    padding-right: var(--muse-space-0, 0);
}

.muse-m-0 {
    margin: var(--muse-space-0, 0);
}

.muse-mr-0 {
    margin-right: var(--muse-space-0, 0);
}

.muse-mb-0 {
    margin-bottom: var(--muse-space-0, 0);
}

.muse-ml-0 {
    margin-left: var(--muse-space-0, 0);
}

.muse-mt-0 {
    margin-top: var(--muse-space-0, 0);
}

.muse-my-0 {
    margin: var(--muse-space-0, 0);
}

.muse-mx-0 {
    margin: var(--muse-space-0, 0);
}

.muse-p-10 {
    padding: var(--muse-space-10, 0.2rem);
}

.muse-pr-10 {
    padding-right: var(--muse-space-10, 0.2rem);
}

.muse-pb-10 {
    padding-bottom: var(--muse-space-10, 0.2rem);
}

.muse-pl-10 {
    padding-left: var(--muse-space-10, 0.2rem);
}

.muse-pt-10 {
    padding-top: var(--muse-space-10, 0.2rem);
}

.muse-py-10 {
    padding-bottom: var(--muse-space-10, 0.2rem);
    padding-top: var(--muse-space-10, 0.2rem);
}

.muse-px-10 {
    padding-left: var(--muse-space-10, 0.2rem);
    padding-right: var(--muse-space-10, 0.2rem);
}

.muse-m-10 {
    margin: var(--muse-space-10, 0.2rem);
}

.muse-mr-10 {
    margin-right: var(--muse-space-10, 0.2rem);
}

.muse-mb-10 {
    margin-bottom: var(--muse-space-10, 0.2rem);
}

.muse-ml-10 {
    margin-left: var(--muse-space-10, 0.2rem) !important;
}

.muse-mt-10 {
    margin-top: var(--muse-space-10, 0.2rem);
}

.muse-my-10 {
    margin: var(--muse-space-10, 0.2rem);
}

.muse-mx-10 {
    margin: var(--muse-space-10, 0.2rem);
}

.muse-p-20 {
    padding: var(--muse-space-20, 0.4rem);
}

.muse-pr-20 {
    padding-right: var(--muse-space-20, 0.4rem);
}

.muse-pb-20 {
    padding-bottom: var(--muse-space-20, 0.4rem);
}

.muse-pl-20 {
    padding-left: var(--muse-space-20, 0.4rem);
}

.muse-pt-20 {
    padding-top: var(--muse-space-20, 0.4rem);
}

.muse-py-20 {
    padding-bottom: var(--muse-space-20, 0.4rem);
    padding-top: var(--muse-space-20, 0.4rem);
}

.muse-px-20 {
    padding-left: var(--muse-space-20, 0.4rem);
    padding-right: var(--muse-space-20, 0.4rem);
}

.muse-m-20 {
    margin: var(--muse-space-20, 0.4rem);
}

.muse-mr-20 {
    margin-right: var(--muse-space-20, 0.4rem);
}

.muse-mb-20 {
    margin-bottom: var(--muse-space-20, 0.4rem);
}

.muse-ml-20 {
    margin-left: var(--muse-space-20, 0.4rem);
}

.muse-mt-20 {
    margin-top: var(--muse-space-20, 0.4rem);
}

.muse-my-20 {
    margin: var(--muse-space-20, 0.4rem);
}

.muse-mx-20 {
    margin: var(--muse-space-20, 0.4rem);
}

.muse-p-30 {
    padding: var(--muse-space-30, 0.8rem);
}

.muse-pr-30 {
    padding-right: var(--muse-space-30, 0.8rem);
}

.muse-pb-30 {
    padding-bottom: var(--muse-space-30, 0.8rem);
}

.muse-pl-30 {
    padding-left: var(--muse-space-30, 0.8rem);
}

.muse-pt-30 {
    padding-top: var(--muse-space-30, 0.8rem);
}

.muse-py-30 {
    padding-bottom: var(--muse-space-30, 0.8rem);
    padding-top: var(--muse-space-30, 0.8rem);
}

.muse-px-30 {
    padding-left: var(--muse-space-30, 0.8rem);
    padding-right: var(--muse-space-30, 0.8rem);
}

.muse-m-30 {
    margin: var(--muse-space-30, 0.8rem);
}

.muse-mr-30 {
    margin-right: var(--muse-space-30, 0.8rem);
}

.muse-mb-30 {
    margin-bottom: var(--muse-space-30, 0.8rem);
}

.muse-ml-30 {
    margin-left: var(--muse-space-30, 0.8rem);
}

.muse-mt-30 {
    margin-top: var(--muse-space-30, 0.8rem);
}

.muse-my-30 {
    margin: var(--muse-space-30, 0.8rem);
}

.muse-mx-30 {
    margin: var(--muse-space-30, 0.8rem);
}

.muse-p-40 {
    padding: var(--muse-space-40, 1.2rem);
}

.muse-pr-40 {
    padding-right: var(--muse-space-40, 1.2rem);
}

.muse-pb-40 {
    padding-bottom: var(--muse-space-40, 1.2rem);
}

.muse-pl-40 {
    padding-left: var(--muse-space-40, 1.2rem);
}

.muse-pt-40 {
    padding-top: var(--muse-space-40, 1.2rem);
}

.muse-py-40 {
    padding-bottom: var(--muse-space-40, 1.2rem);
    padding-top: var(--muse-space-40, 1.2rem);
}

.muse-px-40 {
    padding-left: var(--muse-space-40, 1.2rem);
    padding-right: var(--muse-space-40, 1.2rem);
}

.muse-m-40 {
    margin: var(--muse-space-40, 1.2rem);
}

.muse-mr-40 {
    margin-right: var(--muse-space-40, 1.2rem);
}

.muse-mb-40 {
    margin-bottom: var(--muse-space-40, 1.2rem);
}

.muse-ml-40 {
    margin-left: var(--muse-space-40, 1.2rem);
}

.muse-mt-40 {
    margin-top: var(--muse-space-40, 1.2rem);
}

.muse-my-40 {
    margin: var(--muse-space-40, 1.2rem);
}

.muse-mx-40 {
    margin: var(--muse-space-40, 1.2rem);
}

.muse-p-50 {
    padding: var(--muse-space-50, 1.6rem);
}

.muse-pr-50 {
    padding-right: var(--muse-space-50, 1.6rem);
}

.muse-pb-50 {
    padding-bottom: var(--muse-space-50, 1.6rem);
}

.muse-pl-50 {
    padding-left: var(--muse-space-50, 1.6rem);
}

.muse-pt-50 {
    padding-top: var(--muse-space-50, 1.6rem);
}

.muse-py-50 {
    padding-bottom: var(--muse-space-50, 1.6rem);
    padding-top: var(--muse-space-50, 1.6rem);
}

.muse-px-50 {
    padding-left: var(--muse-space-50, 1.6rem);
    padding-right: var(--muse-space-50, 1.6rem);
}

.muse-m-50 {
    margin: var(--muse-space-50, 1.6rem);
}

.muse-mr-50 {
    margin-right: var(--muse-space-50, 1.6rem);
}

.muse-mb-50 {
    margin-bottom: var(--muse-space-50, 1.6rem);
}

.muse-ml-50 {
    margin-left: var(--muse-space-50, 1.6rem);
}

.muse-mt-50 {
    margin-top: var(--muse-space-50, 1.6rem);
}

.muse-my-50 {
    margin: var(--muse-space-50, 1.6rem);
}

.muse-mx-50 {
    margin: var(--muse-space-50, 1.6rem);
}

.muse-p-60 {
    padding: var(--muse-space-60, 2rem);
}

.muse-pr-60 {
    padding-right: var(--muse-space-60, 2rem);
}

.muse-pb-60 {
    padding-bottom: var(--muse-space-60, 2rem);
}

.muse-pl-60 {
    padding-left: var(--muse-space-60, 2rem);
}

.muse-pt-60 {
    padding-top: var(--muse-space-60, 2rem);
}

.muse-py-60 {
    padding-bottom: var(--muse-space-60, 2rem);
    padding-top: var(--muse-space-60, 2rem);
}

.muse-px-60 {
    padding-left: var(--muse-space-60, 2rem);
    padding-right: var(--muse-space-60, 2rem);
}

.muse-m-60 {
    margin: var(--muse-space-60, 2rem);
}

.muse-mr-60 {
    margin-right: var(--muse-space-60, 2rem);
}

.muse-mb-60 {
    margin-bottom: var(--muse-space-60, 2rem);
}

.muse-ml-60 {
    margin-left: var(--muse-space-60, 2rem);
}

.muse-mt-60 {
    margin-top: var(--muse-space-60, 2rem);
}

.muse-my-60 {
    margin: var(--muse-space-60, 2rem);
}

.muse-mx-60 {
    margin: var(--muse-space-60, 2rem);
}

.muse-p-70 {
    padding: var(--muse-space-70, 2.4rem);
}

.muse-pr-70 {
    padding-right: var(--muse-space-70, 2.4rem);
}

.muse-pb-70 {
    padding-bottom: var(--muse-space-70, 2.4rem);
}

.muse-pl-70 {
    padding-left: var(--muse-space-70, 2.4rem);
}

.muse-pt-70 {
    padding-top: var(--muse-space-70, 2.4rem);
}

.muse-py-70 {
    padding-bottom: var(--muse-space-70, 2.4rem);
    padding-top: var(--muse-space-70, 2.4rem);
}

.muse-px-70 {
    padding-left: var(--muse-space-70, 2.4rem);
    padding-right: var(--muse-space-70, 2.4rem);
}

.muse-m-70 {
    margin: var(--muse-space-70, 2.4rem);
}

.muse-mr-70 {
    margin-right: var(--muse-space-70, 2.4rem);
}

.muse-mb-70 {
    margin-bottom: var(--muse-space-70, 2.4rem);
}

.muse-ml-70 {
    margin-left: var(--muse-space-70, 2.4rem);
}

.muse-mt-70 {
    margin-top: var(--muse-space-70, 2.4rem);
}

.muse-my-70 {
    margin: var(--muse-space-70, 2.4rem);
}

.muse-mx-70 {
    margin: var(--muse-space-70, 2.4rem);
}

.muse-p-80 {
    padding: var(--muse-space-80, 2.8rem);
}

.muse-pr-80 {
    padding-right: var(--muse-space-80, 2.8rem);
}

.muse-pb-80 {
    padding-bottom: var(--muse-space-80, 2.8rem);
}

.muse-pl-80 {
    padding-left: var(--muse-space-80, 2.8rem);
}

.muse-pt-80 {
    padding-top: var(--muse-space-80, 2.8rem);
}

.muse-py-80 {
    padding-bottom: var(--muse-space-80, 2.8rem);
    padding-top: var(--muse-space-80, 2.8rem);
}

.muse-px-80 {
    padding-left: var(--muse-space-80, 2.8rem);
    padding-right: var(--muse-space-80, 2.8rem);
}

.muse-m-80 {
    margin: var(--muse-space-80, 2.8rem);
}

.muse-mr-80 {
    margin-right: var(--muse-space-80, 2.8rem);
}

.muse-mb-80 {
    margin-bottom: var(--muse-space-80, 2.8rem);
}

.muse-ml-80 {
    margin-left: var(--muse-space-80, 2.8rem);
}

.muse-mt-80 {
    margin-top: var(--muse-space-80, 2.8rem);
}

.muse-my-80 {
    margin: var(--muse-space-80, 2.8rem);
}

.muse-mx-80 {
    margin: var(--muse-space-80, 2.8rem);
}

.muse-p-90 {
    padding: var(--muse-space-90, 3.2rem);
}

.muse-pr-90 {
    padding-right: var(--muse-space-90, 3.2rem);
}

.muse-pb-90 {
    padding-bottom: var(--muse-space-90, 3.2rem);
}

.muse-pl-90 {
    padding-left: var(--muse-space-90, 3.2rem);
}

.muse-pt-90 {
    padding-top: var(--muse-space-90, 3.2rem);
}

.muse-py-90 {
    padding-bottom: var(--muse-space-90, 3.2rem);
    padding-top: var(--muse-space-90, 3.2rem);
}

.muse-px-90 {
    padding-left: var(--muse-space-90, 3.2rem);
    padding-right: var(--muse-space-90, 3.2rem);
}

.muse-m-90 {
    margin: var(--muse-space-90, 3.2rem);
}

.muse-mr-90 {
    margin-right: var(--muse-space-90, 3.2rem);
}

.muse-mb-90 {
    margin-bottom: var(--muse-space-90, 3.2rem);
}

.muse-ml-90 {
    margin-left: var(--muse-space-90, 3.2rem);
}

.muse-mt-90 {
    margin-top: var(--muse-space-90, 3.2rem);
}

.muse-my-90 {
    margin: var(--muse-space-90, 3.2rem);
}

.muse-mx-90 {
    margin: var(--muse-space-90, 3.2rem);
}

.muse-p-100 {
    padding: var(--muse-space-100, 3.6rem);
}

.muse-pr-100 {
    padding-right: var(--muse-space-100, 3.6rem);
}

.muse-pb-100 {
    padding-bottom: var(--muse-space-100, 3.6rem);
}

.muse-pl-100 {
    padding-left: var(--muse-space-100, 3.6rem);
}

.muse-pt-100 {
    padding-top: var(--muse-space-100, 3.6rem);
}

.muse-py-100 {
    padding-bottom: var(--muse-space-100, 3.6rem);
    padding-top: var(--muse-space-100, 3.6rem);
}

.muse-px-100 {
    padding-left: var(--muse-space-100, 3.6rem);
    padding-right: var(--muse-space-100, 3.6rem);
}

.muse-m-100 {
    margin: var(--muse-space-100, 3.6rem);
}

.muse-mr-100 {
    margin-right: var(--muse-space-100, 3.6rem);
}

.muse-mb-100 {
    margin-bottom: var(--muse-space-100, 3.6rem);
}

.muse-ml-100 {
    margin-left: var(--muse-space-100, 3.6rem);
}

.muse-mt-100 {
    margin-top: var(--muse-space-100, 3.6rem);
}

.muse-my-100 {
    margin: var(--muse-space-100, 3.6rem);
}

.muse-mx-100 {
    margin: var(--muse-space-100, 3.6rem);
}

.muse-p-110 {
    padding: var(--muse-space-110, 4rem);
}

.muse-pr-110 {
    padding-right: var(--muse-space-110, 4rem);
}

.muse-pb-110 {
    padding-bottom: var(--muse-space-110, 4rem);
}

.muse-pl-110 {
    padding-left: var(--muse-space-110, 4rem);
}

.muse-pt-110 {
    padding-top: var(--muse-space-110, 4rem);
}

.muse-py-110 {
    padding-bottom: var(--muse-space-110, 4rem);
    padding-top: var(--muse-space-110, 4rem);
}

.muse-px-110 {
    padding-left: var(--muse-space-110, 4rem);
    padding-right: var(--muse-space-110, 4rem);
}

.muse-m-110 {
    margin: var(--muse-space-110, 4rem);
}

.muse-mr-110 {
    margin-right: var(--muse-space-110, 4rem);
}

.muse-mb-110 {
    margin-bottom: var(--muse-space-110, 4rem);
}

.muse-ml-110 {
    margin-left: var(--muse-space-110, 4rem);
}

.muse-mt-110 {
    margin-top: var(--muse-space-110, 4rem);
}

.muse-my-110 {
    margin: var(--muse-space-110, 4rem);
}

.muse-mx-110 {
    margin: var(--muse-space-110, 4rem);
}

.muse-p-120 {
    padding: var(--muse-space-120, 4.8rem);
}

.muse-pr-120 {
    padding-right: var(--muse-space-120, 4.8rem);
}

.muse-pb-120 {
    padding-bottom: var(--muse-space-120, 4.8rem);
}

.muse-pl-120 {
    padding-left: var(--muse-space-120, 4.8rem);
}

.muse-pt-120 {
    padding-top: var(--muse-space-120, 4.8rem);
}

.muse-py-120 {
    padding-bottom: var(--muse-space-120, 4.8rem);
    padding-top: var(--muse-space-120, 4.8rem);
}

.muse-px-120 {
    padding-left: var(--muse-space-120, 4.8rem);
    padding-right: var(--muse-space-120, 4.8rem);
}

.muse-m-120 {
    margin: var(--muse-space-120, 4.8rem);
}

.muse-mr-120 {
    margin-right: var(--muse-space-120, 4.8rem);
}

.muse-mb-120 {
    margin-bottom: var(--muse-space-120, 4.8rem);
}

.muse-ml-120 {
    margin-left: var(--muse-space-120, 4.8rem);
}

.muse-mt-120 {
    margin-top: var(--muse-space-120, 4.8rem);
}

.muse-my-120 {
    margin: var(--muse-space-120, 4.8rem);
}

.muse-mx-120 {
    margin: var(--muse-space-120, 4.8rem);
}

.muse-p-130 {
    padding: var(--muse-space-130, 5.6rem);
}

.muse-pr-130 {
    padding-right: var(--muse-space-130, 5.6rem);
}

.muse-pb-130 {
    padding-bottom: var(--muse-space-130, 5.6rem);
}

.muse-pl-130 {
    padding-left: var(--muse-space-130, 5.6rem);
}

.muse-pt-130 {
    padding-top: var(--muse-space-130, 5.6rem);
}

.muse-py-130 {
    padding-bottom: var(--muse-space-130, 5.6rem);
    padding-top: var(--muse-space-130, 5.6rem);
}

.muse-px-130 {
    padding-left: var(--muse-space-130, 5.6rem);
    padding-right: var(--muse-space-130, 5.6rem);
}

.muse-m-130 {
    margin: var(--muse-space-130, 5.6rem);
}

.muse-mr-130 {
    margin-right: var(--muse-space-130, 5.6rem);
}

.muse-mb-130 {
    margin-bottom: var(--muse-space-130, 5.6rem);
}

.muse-ml-130 {
    margin-left: var(--muse-space-130, 5.6rem);
}

.muse-mt-130 {
    margin-top: var(--muse-space-130, 5.6rem);
}

.muse-my-130 {
    margin: var(--muse-space-130, 5.6rem);
}

.muse-mx-130 {
    margin: var(--muse-space-130, 5.6rem);
}

.muse-p-140 {
    padding: var(--muse-space-140, 6.4rem);
}

.muse-pr-140 {
    padding-right: var(--muse-space-140, 6.4rem);
}

.muse-pb-140 {
    padding-bottom: var(--muse-space-140, 6.4rem);
}

.muse-pl-140 {
    padding-left: var(--muse-space-140, 6.4rem);
}

.muse-pt-140 {
    padding-top: var(--muse-space-140, 6.4rem);
}

.muse-py-140 {
    padding-bottom: var(--muse-space-140, 6.4rem);
    padding-top: var(--muse-space-140, 6.4rem);
}

.muse-px-140 {
    padding-left: var(--muse-space-140, 6.4rem);
    padding-right: var(--muse-space-140, 6.4rem);
}

.muse-m-140 {
    margin: var(--muse-space-140, 6.4rem);
}

.muse-mr-140 {
    margin-right: var(--muse-space-140, 6.4rem);
}

.muse-mb-140 {
    margin-bottom: var(--muse-space-140, 6.4rem);
}

.muse-ml-140 {
    margin-left: var(--muse-space-140, 6.4rem);
}

.muse-mt-140 {
    margin-top: var(--muse-space-140, 6.4rem);
}

.muse-my-140 {
    margin: var(--muse-space-140, 6.4rem);
}

.muse-mx-140 {
    margin: var(--muse-space-140, 6.4rem);
}

.muse-p-150 {
    padding: var(--muse-space-150, 7.2rem);
}

.muse-pr-150 {
    padding-right: var(--muse-space-150, 7.2rem);
}

.muse-pb-150 {
    padding-bottom: var(--muse-space-150, 7.2rem);
}

.muse-pl-150 {
    padding-left: var(--muse-space-150, 7.2rem);
}

.muse-pt-150 {
    padding-top: var(--muse-space-150, 7.2rem);
}

.muse-py-150 {
    padding-bottom: var(--muse-space-150, 7.2rem);
    padding-top: var(--muse-space-150, 7.2rem);
}

.muse-px-150 {
    padding-left: var(--muse-space-150, 7.2rem);
    padding-right: var(--muse-space-150, 7.2rem);
}

.muse-m-150 {
    margin: var(--muse-space-150, 7.2rem);
}

.muse-mr-150 {
    margin-right: var(--muse-space-150, 7.2rem);
}

.muse-mb-150 {
    margin-bottom: var(--muse-space-150, 7.2rem);
}

.muse-ml-150 {
    margin-left: var(--muse-space-150, 7.2rem);
}

.muse-mt-150 {
    margin-top: var(--muse-space-150, 7.2rem);
}

.muse-my-150 {
    margin: var(--muse-space-150, 7.2rem);
}

.muse-mx-150 {
    margin: var(--muse-space-150, 7.2rem);
}

.muse-p-160 {
    padding: var(--muse-space-160, 8rem);
}

.muse-pr-160 {
    padding-right: var(--muse-space-160, 8rem);
}

.muse-pb-160 {
    padding-bottom: var(--muse-space-160, 8rem);
}

.muse-pl-160 {
    padding-left: var(--muse-space-160, 8rem);
}

.muse-pt-160 {
    padding-top: var(--muse-space-160, 8rem);
}

.muse-py-160 {
    padding-bottom: var(--muse-space-160, 8rem);
    padding-top: var(--muse-space-160, 8rem);
}

.muse-px-160 {
    padding-left: var(--muse-space-160, 8rem);
    padding-right: var(--muse-space-160, 8rem);
}

.muse-m-160 {
    margin: var(--muse-space-160, 8rem);
}

.muse-mr-160 {
    margin-right: var(--muse-space-160, 8rem);
}

.muse-mb-160 {
    margin-bottom: var(--muse-space-160, 8rem);
}

.muse-ml-160 {
    margin-left: var(--muse-space-160, 8rem);
}

.muse-mt-160 {
    margin-top: var(--muse-space-160, 8rem);
}

.muse-my-160 {
    margin: var(--muse-space-160, 8rem);
}

.muse-mx-160 {
    margin: var(--muse-space-160, 8rem);
}

.muse-p-170 {
    padding: var(--muse-space-170, 12rem);
}

.muse-pr-170 {
    padding-right: var(--muse-space-170, 12rem);
}

.muse-pb-170 {
    padding-bottom: var(--muse-space-170, 12rem);
}

.muse-pl-170 {
    padding-left: var(--muse-space-170, 12rem);
}

.muse-pt-170 {
    padding-top: var(--muse-space-170, 12rem);
}

.muse-py-170 {
    padding-bottom: var(--muse-space-170, 12rem);
    padding-top: var(--muse-space-170, 12rem);
}

.muse-px-170 {
    padding-left: var(--muse-space-170, 12rem);
    padding-right: var(--muse-space-170, 12rem);
}

.muse-m-170 {
    margin: var(--muse-space-170, 12rem);
}

.muse-mr-170 {
    margin-right: var(--muse-space-170, 12rem);
}

.muse-mb-170 {
    margin-bottom: var(--muse-space-170, 12rem);
}

.muse-ml-170 {
    margin-left: var(--muse-space-170, 12rem);
}

.muse-mt-170 {
    margin-top: var(--muse-space-170, 12rem);
}

.muse-my-170 {
    margin: var(--muse-space-170, 12rem);
}

.muse-mx-170 {
    margin: var(--muse-space-170, 12rem);
}

.muse-border-regular {
    border-width: var(--muse-border-width-regular, 1px);
}

.muse-border-thick {
    border-width: var(--muse-border-width-thick, 2px);
}

.muse-radius-0 {
    border-radius: var(--muse-radius-0, 0px);
}

.muse-radius-10 {
    border-radius: var(--muse-radius-10, 2px);
}

.muse-radius-20 {
    border-radius: var(--muse-radius-20, 4px);
}

.muse-radius-30 {
    border-radius: var(--muse-radius-30, 8px);
}

.muse-radius-40 {
    border-radius: var(--muse-radius-40, 24px);
}

.muse-radius-50 {
    border-radius: var(--muse-radius-50, 48px);
}

.muse-radius-circle {
    border-radius: var(--muse-radius-circle, 100%);
}

.muse-font-default {
    font-family: var(--muse-font-family-default, "CiscoSans", "CiscoSansTT", sans-serif);
}

.muse-font-100 {
    font-weight: var(--muse-font-weight-100, 100);
}

.muse-font-200 {
    font-weight: var(--muse-font-weight-200, 200);
}

.muse-font-300 {
    font-weight: var(--muse-font-weight-300, 300);
}

.muse-font-400 {
    font-weight: var(--muse-font-weight-400, 400);
}

.muse-font-500 {
    font-weight: var(--muse-font-weight-500, 500);
}

.muse-font-600 {
    font-weight: var(--muse-font-weight-600, 600);
}

.muse-font-700 {
    font-weight: var(--muse-font-weight-700, 700);
}

.muse-text-10 {
    font-size: var(--muse-font-size-10, 1.2rem);
}

.muse-text-20 {
    font-size: var(--muse-font-size-20, 1.4rem);
}

.muse-text-30 {
    font-size: var(--muse-font-size-30, 1.6rem);
}

.muse-text-40 {
    font-size: var(--muse-font-size-40, 2rem);
}

.muse-text-14 {
    font-size: var(--muse-font-size-40, 2rem);
}

.muse-text-50 {
    font-size: var(--muse-font-size-50, 2.4rem);
}

.muse-text-60 {
    font-size: var(--muse-font-size-60, 3.2rem);
}

.muse-text-70 {
    font-size: var(--muse-font-size-70, 4rem);
}

.muse-text-80 {
    font-size: var(--muse-font-size-80, 5.2rem);
}

.muse-text-90 {
    font-size: var(--muse-font-size-90, 6.4rem);
}

.muse-leading-10 {
    line-height: var(--muse-line-height-10, 16px);
}

.muse-leading-20 {
    line-height: var(--muse-line-height-20, 20px);
}

.muse-leading-30 {
    line-height: var(--muse-line-height-30, 24px);
}

.muse-leading-40 {
    line-height: var(--muse-line-height-40, 28px);
}

.muse-leading-50 {
    line-height: var(--muse-line-height-50, 32px);
}

.muse-leading-60 {
    line-height: var(--muse-line-height-60, 40px);
}

.muse-leading-70 {
    line-height: var(--muse-line-height-70, 48px);
}

.muse-leading-80 {
    line-height: var(--muse-line-height-80, 62px);
}

.muse-leading-90 {
    line-height: var(--muse-line-height-90, 77px);
}

.muse-tracking-tight {
    letter-spacing: var(--muse-letter-spacing-tight, -1);
}

.muse-tracking-regular {
    letter-spacing: var(--muse-letter-spacing-regular, 0);
}

.muse-tracking-wide {
    letter-spacing: var(--muse-letter-spacing-wide, 0.2);
}

.muse-gap-tight {
    gap: var(--muse-grid-gap-tight, 2rem);
}

.muse-gap-x-tight {
    column-gap: var(--muse-grid-gap-tight, 2rem);
}

.muse-gap-y-tight {
    row-gap: var(--muse-grid-gap-tight, 2rem);
}

.muse-gap-normal {
    gap: var(--muse-grid-gap-normal, 2.4rem);
}

.muse-gap-x-normal {
    column-gap: var(--muse-grid-gap-normal, 2.4rem);
}

.muse-gap-y-normal {
    row-gap: var(--muse-grid-gap-normal, 2.4rem);
}

.muse-bg-accent {
    background-color: var(--muse-background-accent, #0070d2);
}

.muse-bg-accent-darker {
    background-color: var(--muse-background-accent-darker, #0060ad);
}

.muse-bg-accent-lighter {
    background-color: var(--muse-background-accent-lighter, #008dff);
}

.muse-bg-emphasis {
    background-color: var(--muse-background-emphasis, #0d274d);
}

.muse-bg-emphasis-darker {
    background-color: var(--muse-background-emphasis-darker, #07162c);
}

.muse-bg-neutral {
    background-color: var(--muse-background-neutral, #ffffff);
}

.muse-bg-neutral-dark {
    background-color: var(--muse-background-neutral-dark, #f4f5f6);
}

.muse-bg-neutral-darker {
    background-color: var(--muse-background-neutral-darker, #ebebeb);
}

.muse-bg-neutral-darker-2 {
    background-color: var(--muse-background-neutral-darker-2, #d6d6d6);
}

.muse-bg-neutral-darkest {
    background-color: var(--muse-background-neutral-darkest, #8e8e8e);
}

.muse-bg-inverse-weak {
    background-color: var(--muse-background-inverse-weak, rgba(255, 255, 255, 0.5));
}

.muse-bg-inverse-weakest {
    background-color: var(--muse-background-inverse-weakest, rgba(255, 255, 255, 0.25));
}

.muse-bg-error {
    background-color: var(--muse-background-error, #db231a);
}

.muse-bg-error-lighter {
    background-color: var(--muse-background-error-lighter, #ff4c44);
}

.muse-bg-success {
    background-color: var(--muse-background-success, #058103);
}

.muse-bg-success-lighter {
    background-color: var(--muse-background-success-lighter, #74bf4b);
}

.muse-bg-warning {
    background-color: var(--muse-background-warning, #d27700);
}

.muse-bg-warning-lighter {
    background-color: var(--muse-background-warning-lighter, #fbab2c);
}

.muse-bg-error-weak {
    background-color: var(--muse-background-error-weak, #fdf4f4);
}

.muse-bg-success-weak {
    background-color: var(--muse-background-success-weak, #f3f9f2);
}

.muse-bg-warning-weak {
    background-color: var(--muse-background-warning-weak, #fdf8f2);
}

.muse-bg-information-weak {
    background-color: var(--muse-background-information-weak, #f2f8fd);
}

.muse-border-accent {
    border-color: var(--muse-border-accent, #0070d2);
}

.muse-border-accent-lighter {
    border-color: var(--muse-border-accent-lighter, #008dff);
}

.muse-border-accent-darker {
    border-color: var(--muse-border-accent-darker, #0060ad);
}

.muse-border-emphasis {
    border-color: var(--muse-border-emphasis, #0d274d);
}

.muse-border-emphasis-darker {
    border-color: var(--muse-border-emphasis-darker, #07162c);
}

.muse-border-default {
    border-color: var(--muse-border-default, #d6d6d6);
}

.muse-border-default-darker {
    border-color: var(--muse-border-default-darker, #8e8e8e);
}

.muse-border-inverse {
    border-color: var(--muse-border-inverse, #ffffff);
}

.muse-border-inverse-darker {
    border-color: var(--muse-border-inverse-darker, #ebebeb);
}

.muse-border-inverse-weak {
    border-color: var(--muse-border-inverse-weak, rgba(255, 255, 255, 0.5));
}

.muse-border-inverse-weakest {
    border-color: var(--muse-border-inverse-weakest, rgba(255, 255, 255, 0.25));
}

.muse-border-error {
    border-color: var(--muse-border-error, #db231a);
}

.muse-border-error-light {
    border-color: var(--muse-border-error-light, #ff4c44);
}

.muse-border-success {
    border-color: var(--muse-border-success, #058103);
}

.muse-border-success-light {
    border-color: var(--muse-border-success-light, #74bf4b);
}

.muse-border-warning {
    border-color: var(--muse-border-warning, #d27700);
}

.muse-border-warning-lighter {
    border-color: var(--muse-border-warning-lighter, #fbab2c);
}

.muse-text--accent {
    color: var(--muse-text-accent, #0070d2);
}

.muse-text--accent-lighter {
    color: var(--muse-text-accent-lighter, #008dff);
}

.muse-text--accent-darker {
    color: var(--muse-text-accent-darker, #0060ad);
}

.muse-text--emphasis {
    color: var(--muse-text-emphasis, #0d274d);
}

.muse-text--emphasis-darker {
    color: var(--muse-text-emphasis-darker, #07162c);
}

.muse-text--default {
    color: var(--muse-text-default, #1b1c1d);
}

.muse-text--default-lighter {
    color: var(--muse-text-default-lighter, #606162);
}

.muse-text--default-lightest {
    color: var(--muse-text-default-lightest, #8e8e8e);
}

.muse-text--inverse {
    color: var(--muse-text-inverse, #ffffff);
}

.muse-text--inverse-darker {
    color: var(--muse-text-inverse-darker, #f4f5f6);
}

.muse-text--inverse-weak {
    color: var(--muse-text-inverse-weak, rgba(255, 255, 255, 0.5));
}

.muse-text--link-visited {
    color: var(--muse-text-link-visited, #551a8b);
}

.muse-text--link-visited-lighter {
    color: var(--muse-text-link-visited-lighter, #b279e6);
}

.muse-text--error {
    color: var(--muse-text-error, #db231a);
}

.muse-text--error-lighter {
    color: var(--muse-text-error-lighter, #ff4c44);
}

.muse-text--success {
    color: var(--muse-text-success, #058103);
}

.muse-text--success-lighter {
    color: var(--muse-text-success-lighter, #74bf4b);
}

/* stylelint-disable custom-property-pattern -- seems like it gets cranky with the vars */
/* stylelint-disable */
/* stylelint-disable custom-property-pattern -- seems like it gets cranky with the vars */
.muse-text-style-10,
.muse-text-style-caption {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-10);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-10);
    letter-spacing: var(--muse-letter-spacing-wide);
}

.muse-text-style-20,
.muse-text-style-body-reduced {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-20);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-20);
}

.muse-text-style-30,
.muse-text-style-body,
.muse-list,
.muse-list--reduced,
.muse-longform-typography-defaults,
.muse-longform-typography-defaults p,
.muse-longform-typography-defaults .lead-in {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-30);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-30);
}

.muse-text-style-body--bold,
.muse-longform-typography-defaults .h5,
.muse-longform-typography-defaults h5,
.muse-longform-typography-defaults .h6,
.muse-longform-typography-defaults h6 {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-30);
    font-weight: var(--muse-font-weight-500);
    line-height: var(--muse-line-height-30);
}

.muse-text-style-40,
.muse-text-style-title-reduced,
.muse-longform-typography-defaults .h4,
.muse-longform-typography-defaults h4 {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-40);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-40);
    color: var(--text-default-lighter, #606162);
}

.muse-text-style-50,
.muse-text-style-title {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-50);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-50);
}

.muse-text-style-60,
.muse-text-style-heading-reduced {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-60);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-60);
}

.muse-text-style-70,
.muse-text-style-heading {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-70);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-70);
}

.muse-text-style-80,
.muse-text-style-display-reduced {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-80);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-80);
}

.muse-text-style-90,
.muse-text-style-display {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-90);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-90);
    letter-spacing: var(--muse-letter-spacing-tight);
}

.muse-headline-display {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-70);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-70);
}

@media (width >=600px) {
    .muse-headline-display {
        font-size: var(--muse-font-size-80);
        line-height: var(--muse-line-height-80);
    }
}

@media (width >=1440px) {
    .muse-headline-display {
        font-size: var(--muse-font-size-90);
        line-height: var(--muse-line-height-90);
        letter-spacing: var(--muse-letter-spacing-tight);
    }
}

.muse-longform-typography-defaults .h1,
.muse-longform-typography-defaults h1,
.muse-headline-display-reduced {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-60);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-60);
}

@media (width >=600px) {

    .muse-longform-typography-defaults .h1,
    .muse-longform-typography-defaults h1,
    .muse-headline-display-reduced {
        font-size: var(--muse-font-size-70);
        line-height: var(--muse-line-height-70);
    }
}

@media (width >=1024px) {

    .muse-longform-typography-defaults .h1,
    .muse-longform-typography-defaults h1,
    .muse-headline-display-reduced {
        font-size: var(--muse-font-size-80);
        line-height: var(--muse-line-height-80);
    }
}

.muse-headline-heading {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-60);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-60);
}

@media (width >=1024px) {
    .muse-headline-heading {
        font-size: var(--muse-font-size-70);
        line-height: var(--muse-line-height-70);
    }
}

.muse-longform-typography-defaults .h2,
.muse-longform-typography-defaults h2,
.muse-headline-heading-reduced {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-50);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-50);
}

@media (width >=600px) {

    .muse-longform-typography-defaults .h2,
    .muse-longform-typography-defaults h2,
    .muse-headline-heading-reduced {
        font-size: var(--muse-font-size-60);
        line-height: var(--muse-line-height-60);
    }
}

.muse-longform-typography-defaults .h3,
.muse-longform-typography-defaults h3,
.muse-headline-title {
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-40);
    font-weight: var(--muse-font-weight-300);
    line-height: var(--muse-line-height-50);
}

@media (width >=1440px) {

    .muse-longform-typography-defaults .h3,
    .muse-longform-typography-defaults h3,
    .muse-headline-title {
        font-size: var(--muse-font-size-50);
        line-height: var(--muse-line-height-50);
    }
}

/* stylelint-disable scss/at-extend-no-missing-placeholder -- Need to figure out better structure for headline exports. */
.muse-longform-typography-defaults hr,
.muse-longform-typography-defaults .hr,
.muse-longform-typography-defaults .rule {
    width: 48px;
    height: 1px;
    background-color: var(--muse-border-default);
    margin-top: var(--muse-space-120);
    margin-bottom: var(--muse-space-120);
    margin-left: var(--muse-space-0);
}

.muse-longform-typography-defaults p {
    margin-top: 0;
}

.muse-longform-typography-defaults p:has(+ :is(.a,
        .image,
        .muse-button-group,
        .muse-button-primary,
        .muse-button-secondary,
        .picture,
        .table,
        .video,
        .list-label,
        a,
        figure,
        img,
        p,
        picture,
        table,
        video,
        blockquote,
        pre)) {
    margin-bottom: var(--muse-space-70);
}

.muse-longform-typography-defaults p:has(+ :is(h1, .h1, h2, .h2, h4, .h4)) {
    margin-bottom: var(--muse-space-100);
}

.muse-longform-typography-defaults p:has(+ :is(h3, .h3)) {
    margin-bottom: var(--muse-space-100);
}

.muse-longform-typography-defaults p:has(+ :is(h5, .h5, h6, .h6)) {
    margin-bottom: var(--muse-space-70);
}

.muse-longform-typography-defaults p:has(+ :is(ol, ul)) {
    margin-bottom: var(--muse-space-50);
}

.muse-longform-typography-defaults .lead-in:has(+ :is(p, h3, .h3, h4, .h4)) {
    margin-bottom: var(--muse-space-70);
}

.muse-longform-typography-defaults .h1,
.muse-longform-typography-defaults h1 {
    margin-top: 0;
}

.muse-longform-typography-defaults .h1:has(+ :is(p, blockquote, .blockquote, pre)),
.muse-longform-typography-defaults h1:has(+ :is(p, blockquote, .blockquote, pre)) {
    margin-bottom: var(--muse-space-50);
}

.muse-longform-typography-defaults .h2,
.muse-longform-typography-defaults h2 {
    margin-top: 0;
}

.muse-longform-typography-defaults .h2:has(+ :is(p, blockquote, .blockquote, pre)),
.muse-longform-typography-defaults h2:has(+ :is(p, blockquote, .blockquote, pre)) {
    margin-bottom: var(--muse-space-50);
}

.muse-longform-typography-defaults .h2:has(+ .lead-in),
.muse-longform-typography-defaults h2:has(+ .lead-in) {
    margin-bottom: var(--muse-space-50);
}

.muse-longform-typography-defaults .h2:has(+ :is(h3, .h3, h4, .h4, h5, .h5, h6, .h6, ul, .ul, ol, .ol)),
.muse-longform-typography-defaults h2:has(+ :is(h3, .h3, h4, .h4, h5, .h5, h6, .h6, ul, .ul, ol, .ol)) {
    margin-bottom: var(--muse-space-50);
}

.muse-longform-typography-defaults .h2:has(+ :is(picture, img, .picture, .image, video, .video, figure)),
.muse-longform-typography-defaults h2:has(+ :is(picture, img, .picture, .image, video, .video, figure)) {
    margin-bottom: var(--muse-space-100);
}

@media (width >=600px) {

    .muse-longform-typography-defaults .h2:has(+ :is(picture, img, .picture, .image, video, .video, figure)),
    .muse-longform-typography-defaults h2:has(+ :is(picture, img, .picture, .image, video, .video, figure)) {
        margin-bottom: var(--muse-space-70);
    }
}

.muse-longform-typography-defaults .h3,
.muse-longform-typography-defaults h3 {
    margin-top: 0;
}

.muse-longform-typography-defaults .h3:has(+ :is(p, blockquote, .blockquote, pre)),
.muse-longform-typography-defaults h3:has(+ :is(p, blockquote, .blockquote, pre)) {
    margin-bottom: var(--muse-space-40);
}

@media (width >=600px) {

    .muse-longform-typography-defaults .h3:has(+ :is(p, blockquote, .blockquote, pre)),
    .muse-longform-typography-defaults h3:has(+ :is(p, blockquote, .blockquote, pre)) {
        margin-bottom: var(--muse-space-50);
    }
}

.muse-longform-typography-defaults .h3:has(+ :is(h4, .h4)),
.muse-longform-typography-defaults h3:has(+ :is(h4, .h4)) {
    margin-bottom: var(--muse-space-50);
}

.muse-longform-typography-defaults .h3:has(+ :is(h5, .h5)),
.muse-longform-typography-defaults h3:has(+ :is(h5, .h5)) {
    margin-bottom: var(--muse-space-50);
}

.muse-longform-typography-defaults .h3:has(+ :is(.image, .picture, .table, .video, figure, img, picture, table, video)),
.muse-longform-typography-defaults h3:has(+ :is(.image, .picture, .table, .video, figure, img, picture, table, video)) {
    margin-bottom: var(--muse-space-70);
}

.muse-longform-typography-defaults .h4,
.muse-longform-typography-defaults h4 {
    margin-top: 0;
}

.muse-longform-typography-defaults .h4:has(+ :is(p, blockquote, .blockquote, pre)),
.muse-longform-typography-defaults h4:has(+ :is(p, blockquote, .blockquote, pre)) {
    margin-bottom: var(--muse-space-40);
}

.muse-longform-typography-defaults .h5,
.muse-longform-typography-defaults h5,
.muse-longform-typography-defaults .h6,
.muse-longform-typography-defaults h6 {
    margin-top: 0;
}

.muse-longform-typography-defaults .h5:has(+ :is(p, blockquote, .blockquote, pre)),
.muse-longform-typography-defaults h5:has(+ :is(p, blockquote, .blockquote, pre)),
.muse-longform-typography-defaults .h6:has(+ :is(p, blockquote, .blockquote, pre)),
.muse-longform-typography-defaults h6:has(+ :is(p, blockquote, .blockquote, pre)) {
    margin-bottom: var(--muse-space-30);
}

.muse-longform-typography-defaults .h5:has(+ :is(ul, ol, .ul, .ol)),
.muse-longform-typography-defaults h5:has(+ :is(ul, ol, .ul, .ol)),
.muse-longform-typography-defaults .h6:has(+ :is(ul, ol, .ul, .ol)),
.muse-longform-typography-defaults h6:has(+ :is(ul, ol, .ul, .ol)) {
    margin-bottom: var(--muse-space-50);
}

.muse-longform-typography-defaults ul,
.muse-longform-typography-defaults ol,
.muse-longform-typography-defaults .ul,
.muse-longform-typography-defaults .ol {
    font-size: var(--muse-font-size-30);
    line-height: var(--muse-space-40);
    margin-bottom: var(--muse-space-0);
    margin-left: var(--muse-space-0);
    margin-top: 0;
    padding-left: 0;
}

.muse-longform-typography-defaults ul:has(+ :is(h3, .h3, h4, .h4)),
.muse-longform-typography-defaults ol:has(+ :is(h3, .h3, h4, .h4)),
.muse-longform-typography-defaults .ul:has(+ :is(h3, .h3, h4, .h4)),
.muse-longform-typography-defaults .ol:has(+ :is(h3, .h3, h4, .h4)) {
    margin-bottom: var(--muse-space-100);
}

.muse-longform-typography-defaults ul:has(+ p),
.muse-longform-typography-defaults ol:has(+ p),
.muse-longform-typography-defaults .ul:has(+ p),
.muse-longform-typography-defaults .ol:has(+ p) {
    margin-bottom: var(--muse-space-70);
}

.muse-longform-typography-defaults ul:has(+ :is(h6, .h6, ul, ol, .ul, .ol)),
.muse-longform-typography-defaults ol:has(+ :is(h6, .h6, ul, ol, .ul, .ol)),
.muse-longform-typography-defaults .ul:has(+ :is(h6, .h6, ul, ol, .ul, .ol)),
.muse-longform-typography-defaults .ol:has(+ :is(h6, .h6, ul, ol, .ul, .ol)) {
    margin-bottom: var(--muse-space-100);
}

.muse-longform-typography-defaults ul li,
.muse-longform-typography-defaults ol li,
.muse-longform-typography-defaults .ul li,
.muse-longform-typography-defaults .ol li {
    margin: 0 0 var(--muse-space-50) var(--muse-space-50);
    line-height: var(--muse-space-70);
}

.muse-longform-typography-defaults ul li ul,
.muse-longform-typography-defaults ul li ol,
.muse-longform-typography-defaults ol li ul,
.muse-longform-typography-defaults ol li ol,
.muse-longform-typography-defaults .ul li ul,
.muse-longform-typography-defaults .ul li ol,
.muse-longform-typography-defaults .ol li ul,
.muse-longform-typography-defaults .ol li ol {
    margin-top: var(--muse-space-50);
}

.muse-longform-typography-defaults ul li:last-of-type,
.muse-longform-typography-defaults ol li:last-of-type,
.muse-longform-typography-defaults .ul li:last-of-type,
.muse-longform-typography-defaults .ol li:last-of-type {
    margin-bottom: var(--muse-space-0);
}

.muse-longform-typography-defaults ul li:last-of-type:has(+ :is(p, ul, ol, image, figure, video)),
.muse-longform-typography-defaults ol li:last-of-type:has(+ :is(p, ul, ol, image, figure, video)),
.muse-longform-typography-defaults .ul li:last-of-type:has(+ :is(p, ul, ol, image, figure, video)),
.muse-longform-typography-defaults .ol li:last-of-type:has(+ :is(p, ul, ol, image, figure, video)) {
    margin-bottom: var(--muse-space-70);
}

.muse-longform-typography-defaults ul li:last-of-type:has(+ :is(h3, .h3, h4, .h4)),
.muse-longform-typography-defaults ol li:last-of-type:has(+ :is(h3, .h3, h4, .h4)),
.muse-longform-typography-defaults .ul li:last-of-type:has(+ :is(h3, .h3, h4, .h4)),
.muse-longform-typography-defaults .ol li:last-of-type:has(+ :is(h3, .h3, h4, .h4)) {
    margin-bottom: var(--muse-space-100);
}

.muse-longform-typography-defaults ul {
    list-style-type: revert;
}

.muse-longform-typography-defaults ol,
.muse-longform-typography-defaults .ol {
    margin-left: var(--muse-space-10);
    list-style-type: decimal;
}

.muse-longform-typography-defaults ol ol,
.muse-longform-typography-defaults .ol ol {
    list-style-type: lower-alpha;
}

.muse-longform-typography-defaults ol ol ol,
.muse-longform-typography-defaults .ol ol ol {
    list-style-type: lower-roman;
}

.muse-longform-typography-defaults ol ul,
.muse-longform-typography-defaults .ol ul {
    list-style-type: disc;
}

@supports (-webkit-hyphens: none) {

    .muse-longform-typography-defaults ol,
    .muse-longform-typography-defaults .ol {
        margin-left: var(--muse-space-20);
    }
}

.muse-longform-typography-defaults .list-label,
.muse-longform-typography-defaults table caption {
    font-size: var(--muse-font-size-30);
    line-height: var(--muse-space-50);
    font-weight: var(--muse-font-weight-500);
    margin-bottom: var(--muse-space-50);
    text-align: left;
}

.muse-longform-typography-defaults blockquote,
.muse-longform-typography-defaults .blockquote {
    font-size: var(--muse-font-size-30);
    border-left: 1px solid var(--muse-border-default);
    padding-left: var(--muse-space-40);
    margin-bottom: var(--muse-space-70);
}

.muse-longform-typography-defaults figure {
    margin-bottom: var(--muse-space-70);
}

.muse-longform-typography-defaults figure img:has(+ figcaption) {
    margin-bottom: var(--muse-space-40);
}

.muse-longform-typography-defaults figure figcaption {
    line-height: var(--muse-line-height-10);
    font-size: var(--muse-font-size-20);
    color: var(--muse-text-default-lighter);
}

.muse-longform-typography-defaults figure figcaption a {
    line-height: var(--muse-line-height-10);
}

.muse-longform-typography-defaults pre {
    background-color: var(--muse-background-neutral-dark);
    border: 1px solid var(--muse-border-default);
    color: var(--muse-text-default);
    font-family: "Courier New", Courier, monospace;
    padding: var(--muse-space-100);
    margin: 0;
}

.muse-accordion {
    font-family: var(--muse-font-family-default);
}

.muse-accordion .muse-accordion-section,
.muse-accordion .muse-accordion-section--expanded {}

.muse-accordion .muse-accordion-section:last-of-type,
.muse-accordion .muse-accordion-section--expanded:last-of-type {}

.muse-accordion .muse-accordion-section .muse-accordion-title,
.muse-accordion .muse-accordion-section--expanded .muse-accordion-title {
    display: flex;
    align-items: center;
    color: var(--muse-text-default-lighter);
    padding: var(--muse-space-50) 0;
}

.muse-accordion .muse-accordion-section .muse-accordion-title:hover,
.muse-accordion .muse-accordion-section--expanded .muse-accordion-title:hover {

    cursor: pointer;
}

[class*=__muse-theme--dark] .muse-accordion .muse-accordion-section .muse-accordion-title,
[data-theme=dark] .muse-accordion .muse-accordion-section .muse-accordion-title,
[class*=__muse-theme--dark] .muse-accordion .muse-accordion-section--expanded .muse-accordion-title,
[data-theme=dark] .muse-accordion .muse-accordion-section--expanded .muse-accordion-title {
    color: var(--muse-text-inverse);
}

[class*=__muse-theme--dark] .muse-accordion .muse-accordion-section .muse-accordion-title:hover,
[data-theme=dark] .muse-accordion .muse-accordion-section .muse-accordion-title:hover,
[class*=__muse-theme--dark] .muse-accordion .muse-accordion-section--expanded .muse-accordion-title:hover,
[data-theme=dark] .muse-accordion .muse-accordion-section--expanded .muse-accordion-title:hover {
    color: var(--muse-text-accent-lighter);
}

.muse-accordion .muse-accordion-section .muse-accordion-title .muse-accordion-icon-left,
.muse-accordion .muse-accordion-section--expanded .muse-accordion-title .muse-accordion-icon-left {
    margin-right: var(--muse-space-60);
}

.muse-accordion .muse-accordion-section .muse-accordion-title .muse-accordion-icon-right,
.muse-accordion .muse-accordion-section--expanded .muse-accordion-title .muse-accordion-icon-right {
    margin-left: auto;
    text-align: end;
}

.muse-accordion .muse-accordion-section .muse-accordion-body,
.muse-accordion .muse-accordion-section--expanded .muse-accordion-body {
    padding-top: var(--muse-space-30);
    padding-bottom: var(--muse-space-70);
    font-size: 16px;
    display: none;
}

.muse-accordion .muse-accordion-section--expanded .muse-accordion-title .muse-text-style-title-reduced {
    font-weight: 500;
}

.muse-accordion .muse-accordion-section--expanded .muse-accordion-body {
    display: block;
    color: var(--text-default, #1B1C1D);
}

.muse-avatar {
    align-items: center;
    background: var(--muse-background-neutral-darkest);
    border-radius: 100%;
    color: var(--muse-text-inverse);
    display: flex;
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-20);
    height: var(--muse-size-110);
    justify-content: center;
    object-fit: cover;
    width: var(--muse-size-110);
    position: relative;
    overflow: hidden;
}

.muse-avatar img {
    position: absolute;
}

@media (width >=1024px) {
    .muse-avatar {
        width: var(--muse-size-50);
        height: var(--muse-size-50);
    }

    .muse-avatar span {
        display: block;
        width: 1ch;
        overflow: hidden;
        font-size: 1rem;
    }
}

.muse-badge,
.muse-badge--promo {
    border-radius: var(--muse-radius-10);
    border: 1px solid var(--muse-border-default-darker);
    color: var(--muse-text-default-lighter);
    display: inline-flex;
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-20);
    line-height: var(--muse-line-height-20);
    padding: var(--muse-space-20, 4px) var(--muse-space-30);
}

[class*=__muse-theme--dark] .muse-badge,
[data-theme=dark] .muse-badge,
[class*=__muse-theme--dark] .muse-badge--promo,
[data-theme=dark] .muse-badge--promo {
    border: 1px solid var(--muse-border-inverse);
    color: var(--muse-text-inverse);
}

.muse-badge--promo {
    border: 1px solid var(--muse-border-success);
    color: var(--muse-text-success);
}

[class*=__muse-theme--dark] .muse-badge--promo,
[data-theme=dark] .muse-badge--promo {
    border: 1px solid var(--muse-border-success-light);
    color: var(--muse-text-success-lighter);
}

.muse-button-input-secondary,
.muse-button-input,
.muse-button-secondary,
.muse-button-primary {
    --muse-button-background: var(--muse-background-accent);
    --muse-button-background-hover: var(--muse-background-accent-darker);
    --muse-button-border: 0px solid transparent;
    --muse-button-border-hover: 0px solid transparent;
    --muse-button-focus-ring: 0 0 0 4px var(--muse-border-inverse),
        0 0 0 6px var(--muse-border-accent);
    --muse-button-foreground: var(--muse-text-inverse);
    --muse-button-foreground-hover: var(--muse-text-inverse);
    background: var(--muse-button-background);
    border-radius: var(--muse-radius-50);
    border: var(--muse-button-border);
    color: var(--muse-button-foreground);
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-30);
    line-height: var(--muse-line-height-10);
    font-weight: var(--muse-font-weight-500);
    padding: var(--muse-space-50) var(--muse-space-70);
}

.muse-button-input-secondary:hover,
.muse-button-input:hover,
.muse-button-secondary:hover,
.muse-button-primary:hover {
    background: var(--muse-button-background-hover);
    color: var(--muse-button-foreground-hover);
}

.muse-button-input-secondary:focus-visible,
.muse-button-input:focus-visible,
.muse-button-secondary:focus-visible,
.muse-button-primary:focus-visible {
    box-shadow: var(--muse-button-focus-ring);
    outline: none;
}

.muse-button-primary--small {
    font-size: var(--muse-font-size-20);
    padding: var(--muse-space-40) var(--muse-space-70);
}

.muse-button-primary--contrast {
    --muse-button-background: var(--muse-background-emphasis);
}

.muse-button-primary--contrast:hover {
    --muse-button-background-hover: var(--muse-background-emphasis-darker);
}

[class*=__muse-theme--dark] .muse-button-primary,
[data-theme=dark] .muse-button-primary {
    --muse-button-background: var(--muse-background-accent);
}

[class*=__muse-theme--dark] .muse-button-primary:hover,
[data-theme=dark] .muse-button-primary:hover {
    --muse-button-background-hover: var(--muse-background-accent-lighter, #008dff);
}

[class*=__muse-theme--dark] .muse-button-primary:focus-visible,
[data-theme=dark] .muse-button-primary:focus-visible {
    --muse-button-focus-ring: 0 0 0 2px var(--muse-border-emphasis),
        0 0 0 4px var(--muse-border-inverse), 0 0 0 6px var(--muse-border-accent);
    outline: none;
}

[class*=__muse-theme--dark] .muse-button-primary--contrast,
[data-theme=dark] .muse-button-primary--contrast {
    --muse-button-background: var(--muse-background-neutral);
    --muse-button-foreground: var(--muse-text-emphasis);
}

[class*=__muse-theme--dark] .muse-button-primary--contrast:hover,
[data-theme=dark] .muse-button-primary--contrast:hover {
    --muse-button-background-hover: var(--muse-background-neutral-darker);
    --muse-button-foreground-hover: var(--muse-text-emphasis);
}

.muse-button-secondary {
    --muse-button-background: transparent;
    --muse-button-background-hover: transparent;
    --muse-button-border: 1px solid var(--muse-border-accent);
    --muse-button-focus-ring: 0 0 0 4px var(--muse-border-inverse),
        0 0 0 6px var(--muse-border-accent);
    --muse-button-foreground: var(--muse-text-accent);
    --muse-button-foreground-hover: var(--muse-text-accent-darker);
}

.muse-button-secondary:hover {
    --muse-button-border: 1px solid var(--muse-border-accent-darker);
    --muse-button-foreground: var(--muse-button-foreground-hover);
}

.muse-button-secondary--small {
    font-size: var(--muse-font-size-20);
    padding: var(--muse-space-40) var(--muse-space-70);
}

[class*=__muse-theme--dark] .muse-button-secondary,
[data-theme=dark] .muse-button-secondary {
    --muse-button-border: 1px solid var(--muse-border-inverse);
    --muse-button-foreground: var(--muse-text-inverse);
}

[class*=__muse-theme--dark] .muse-button-secondary--small,
[data-theme=dark] .muse-button-secondary--small {
    font-size: var(--muse-font-size-20);
    padding: var(--muse-space-40) var(--muse-space-70);
}

[class*=__muse-theme--dark] .muse-button-secondary:hover,
[data-theme=dark] .muse-button-secondary:hover {
    --muse-button-background-hover: transparent;
    --muse-button-border: 1px solid var(--muse-border-inverse-darker);
    --muse-button-foreground-hover: var(--muse-text-inverse-darker);
}

[class*=__muse-theme--dark] .muse-button-secondary:focus-visible,
[data-theme=dark] .muse-button-secondary:focus-visible {
    --muse-button-focus-ring: 0 0 0 2px var(--muse-border-emphasis),
        0 0 0 4px var(--muse-border-inverse), 0 0 0 6px var(--muse-border-accent);
    outline: none;
}

.muse-button-input {
    border-radius: var(--muse-radius-30);
}

.muse-button-input--small {
    font-size: var(--muse-font-size-20);
    padding: var(--muse-space-40) var(--muse-space-70);
}

.muse-button-input-secondary {
    --muse-button-background: transparent;
    --muse-button-background-hover: transparent;
    --muse-button-border: 1px solid var(--muse-border-accent);
    --muse-button-focus-ring: 0 0 0 4px var(--muse-border-inverse),
        0 0 0 6px var(--muse-border-accent);
    --muse-button-foreground: var(--muse-text-accent);
    --muse-button-foreground-hover: var(--muse-text-accent-darker);
    border-radius: var(--muse-radius-30);
}

.muse-button-input-secondary:hover {
    --muse-button-border: 1px solid var(--muse-border-accent-darker);
    --muse-button-foreground: var(--muse-button-foreground-hover);
}

.muse-button-input-secondary--small {
    font-size: var(--muse-font-size-20);
    padding: var(--muse-space-40) var(--muse-space-70);
}

[class*=__muse-theme--dark] .muse-button-input-secondary,
[data-theme=dark] .muse-button-input-secondary {
    border-radius: var(--muse-radius-30);
}

[class*=__muse-theme--dark] .muse-button-input-secondary--small,
[data-theme=dark] .muse-button-input-secondary--small {
    font-size: var(--muse-font-size-20);
    padding: var(--muse-space-40) var(--muse-space-70);
}

.muse-card--div,
.muse-card--stroke,
.muse-card--elevated {
    font-family: var(--muse-font-family-default);
    padding: var(--muse-space-100);
}

.muse-card--elevated {
    background: var(--muse-background-neutral);
    box-shadow: var(--muse-elevation-default);
}

[class*=__muse-theme--dark] .muse-card--elevated,
[data-theme=dark] .muse-card--elevated {
    background: var(--muse-background-neutral);
    color: var(--muse-text-default);
}

.muse-card--stroke {
    border-radius: 24px;
    border: 1px solid var(--muse-border-default);
}

[class*=__muse-theme--dark] .muse-card--stroke,
[data-theme=dark] .muse-card--stroke {
    border: 1px solid var(--muse-border-inverse);
    color: var(--muse-text-inverse);
}

.muse-card--div {
    padding: 0 var(--muse-space-100);
}

[class*=__muse-theme--dark] .muse-card--div,
[data-theme=dark] .muse-card--div {
    border-right: 1px solid var(--muse-border-inverse-weakest);
    border-left: 1px solid var(--muse-border-inverse-weakest);
    color: var(--muse-text-inverse);
}

.muse-checkbox--tile-reduced,
.muse-checkbox--tile,
.muse-checkbox--reduced,
.muse-checkbox {
    --muse-checkbox-border-error: var(--muse-text-error);
    --muse-checkbox-border-hover: var(--muse-border-accent-darker);
    --muse-checkbox-border: var(--muse-border-default-darker);
    --muse-checkbox-checked-fill: var(--muse-background-accent);
    --muse-checkbox-text-error: var(--muse-text-error);
    --muse-checkbox-text-hover: var(--muse-text-accent-darker);
    --muse-checkbox-text-secondary: var(--muse-text-default-lighter);
    --muse-checkbox-text: var(--muse-text-default);
    --muse-checkbox-tile-border: var(--muse-border-default);
    display: flex;
    flex-direction: row;
    font-family: var(--muse-font-family-default);
}

[class*=__muse-theme--dark] .muse-checkbox--tile-reduced,
[class*=__muse-theme--dark] .muse-checkbox--tile,
[class*=__muse-theme--dark] .muse-checkbox--reduced,
[data-theme=dark] .muse-checkbox--tile-reduced,
[data-theme=dark] .muse-checkbox--tile,
[data-theme=dark] .muse-checkbox--reduced,
[class*=__muse-theme--dark] .muse-checkbox,
[data-theme=dark] .muse-checkbox {
    --muse-checkbox-border-error: var(--muse-text-error);
    --muse-checkbox-border-hover: var(--muse-border-accent);
    --muse-checkbox-border: var(--muse-border-inverse);
    --muse-checkbox-checked-fill: var(--muse-background-accent);
    --muse-checkbox-text-error: var(--muse-text-error);
    --muse-checkbox-text-hover: var(--muse-text-accent);
    --muse-checkbox-text-secondary: var(--muse-text-inverse);
    --muse-checkbox-text: var(--muse-text-inverse);
    --muse-checkbox-tile-border: var(--muse-border-inverse);
}

.muse-checkbox-input {
    grid-area: checkmark;
    position: absolute;
    visibility: hidden;
}

.muse-checkbox-input:checked+label .muse-checkbox-custom {
    background: var(--muse-checkbox-checked-fill);
    border-color: var(--muse-checkbox-border);
}

.muse-checkbox-input:checked+label .muse-checkbox-custom svg {
    color: var(--muse-text-inverse);
}

.muse-checkbox--tile-reduced label,
.muse-checkbox--tile label,
.muse-checkbox--reduced label,
.muse-checkbox label {
    align-items: center;
    display: grid;
    font-size: var(--muse-font-size-30);
    gap: var(--muse-space-30) var(--muse-space-40);
    grid-template-areas: "checkmark label" ". inline-message";
}

.muse-checkbox--tile-reduced label:hover,
.muse-checkbox--tile label:hover,
.muse-checkbox--reduced label:hover,
.muse-checkbox label:hover {
    cursor: pointer;
}

.muse-checkbox--tile-reduced label:hover .muse-checkbox-text,
.muse-checkbox--tile label:hover .muse-checkbox-text,
.muse-checkbox--reduced label:hover .muse-checkbox-text,
.muse-checkbox label:hover .muse-checkbox-text {
    color: var(--muse-checkbox-text-hover);
}

.muse-checkbox--tile-reduced label:hover .muse-checkbox-custom,
.muse-checkbox--tile label:hover .muse-checkbox-custom,
.muse-checkbox--reduced label:hover .muse-checkbox-custom,
.muse-checkbox label:hover .muse-checkbox-custom {
    border-color: var(--muse-checkbox-border-hover);
}

.muse-checkbox-custom {
    align-items: center;
    border-radius: var(--muse-radius-20);
    border: 1px solid var(--muse-checkbox-border);
    cursor: pointer;
    display: flex;
    grid-area: checkmark;
    height: var(--muse-size-60);
    justify-content: center;
    user-select: none;
    width: var(--muse-size-60);
}

.muse-checkbox-custom svg {
    color: transparent;
}

.muse-checkbox-text {
    grid-area: label;
}

.muse-checkbox--tile-reduced .muse-inline-message,
.muse-checkbox--tile .muse-inline-message,
.muse-checkbox--reduced .muse-inline-message,
.muse-checkbox .muse-inline-message {
    color: var(--muse-checkbox-text-secondary);
    font-size: var(--muse-font-size-20);
    grid-area: inline-message;
}

.muse-checkbox--reduced label {
    font-size: var(--muse-font-size-20);
}

.muse-checkbox--reduced .muse-checkbox-custom {
    height: var(--muse-size-50);
    width: var(--muse-size-50);
}

.muse-checkbox--reduced .muse-inline-message {
    font-size: var(--muse-font-size-10);
}

.muse-checkbox--tile label {
    border-radius: var(--muse-radius-30);
    border: 1px solid var(--muse-checkbox-tile-border);
    padding: var(--muse-space-50);
}

.muse-checkbox--tile label:hover {
    border: 1px solid var(--muse-checkbox-border-hover);
}

.muse-checkbox--tile-reduced label {
    border-radius: var(--muse-radius-30);
    border: 1px solid var(--muse-checkbox-tile-border);
    font-size: var(--muse-font-size-20);
    padding: var(--muse-space-50);
}

.muse-checkbox--tile-reduced label:hover {
    border: 1px solid var(--muse-checkbox-border-hover);
}

.muse-checkbox--tile-reduced .muse-checkbox-custom {
    width: var(--muse-size-50);
    height: var(--muse-size-50);
}

.muse-checkbox--tile-reduced .muse-inline-message {
    font-size: var(--muse-size-40);
}

.muse-checkbox.muse-input-error .muse-checkbox-custom,
.muse-checkbox--reduced.muse-input-error .muse-checkbox-custom {
    border: 1px solid var(--muse-checkbox-border-error);
}

.muse-checkbox--tile.muse-input-error label,
.muse-checkbox--tile-reduced.muse-input-error label {
    border: 1px solid var(--muse-checkbox-text-error);
}

.muse-checkbox--tile.muse-input-error .muse-checkbox-custom,
.muse-checkbox--tile-reduced.muse-input-error .muse-checkbox-custom {
    border: 1px solid var(--muse-checkbox-border-error);
}

.muse-divider,
.muse-divider--vertical {
    --muse-divider-icon-color: var(--muse-text-default);
    --muse-divider-line-color: var(--muse-border-default);
    --muse-divider-page-bg: var(--muse-background-neutral);
    --muse-divider-text-color: var(--muse-text-default-lighter);
    align-items: center;
    background: var(--muse-divider-line-color);
    border: none;
    color: var(--muse-divider-text-color);
    display: flex;
    height: 1px;
    justify-content: center;
    width: 100%;
}

[class*=__muse-theme--dark] .muse-divider,
[data-theme=dark] .muse-divider,
[class*=__muse-theme--dark] .muse-divider--vertical,
[data-theme=dark] .muse-divider--vertical {
    --muse-divider-icon-color: var(--muse-text-inverse);
    --muse-divider-line-color: var(--muse-border-inverse-weakest);
    --muse-divider-page-bg: var(--muse-background-emphasis);
    --muse-divider-text-color: var(--muse-text-inverse);
}

.muse-divider .muse-divider-text,
.muse-divider--vertical .muse-divider-text {
    background: var(--muse-divider-page-bg);
    color: var(--muse-divider-text-color);
    font-family: var(--muse-font-family-default);
    font-size: var(--muse-font-size-30);
    font-weight: var(--muse-font-weight-400);
    padding: 0 var(--muse-space-70);
}

.muse-divider--vertical {
    background: var(--muse-divider-line-color);
    display: block;
    height: 100%;
    min-height: 100px;
    width: 1px;
}

.muse-input {
    font-family: var(--muse-font-family-default);
}

.muse-input-wrapper {
    border-radius: var(--muse-radius-30);
    border: 1px solid var(--muse-border-default);
    font-size: var(--muse-font-size-30);
    /* margin-bottom: var(--muse-space-40);
    padding: 0 var(--muse-space-60) var(--muse-space-40) var(--muse-space-60); */
}

.muse-input-wrapper:focus-within {
    /* border-radius: 12px;
    outline: 2px solid var(--muse-border-accent);
    outline-offset: 6px; */
}

[class*=__muse-theme--dark] .muse-input-wrapper,
[data-theme=dark] .muse-input-wrapper {
    border: 1px solid var(--muse-border-inverse);
}

.muse-input-label {
    background: var(--muse-background-neutral);
    color: var(--muse-text-default-lighter);
    display: block;
    font-size: var(--muse-font-size-20);
    font-weight: var(--muse-font-weight-300);
    height: var(--muse-space-60);
    line-height: var(--muse-size-60);
    margin-top: -10px;
    padding: 0 var(--muse-space-20);
    position: relative;
    width: fit-content;
    z-index: 1;
}

[class*=__muse-theme--dark] .muse-input-label,
[data-theme=dark] .muse-input-label {
    background: var(--muse-background-emphasis);
    color: var(--muse-text-inverse);
}

.muse-input-field {
    background: transparent;
    border: 0;
    font-weight: var(--muse-font-weight-500);
    line-height: var(--muse-size-50);
    margin-top: var(--muse-space-10);
    width: 100%;
    height: 35px;
    margin-left: 15px;
    padding-right: 15px;
}

.muse-input-field::placeholder {
    color: var(--text-default-lighter, #606162);
}

.muse-input-field:focus {
    outline: none;
}

[class*=__muse-theme--dark] .muse-input-field,
[data-theme=dark] .muse-input-field {
    color: var(--muse-text-inverse);
}

.muse-input-link {
    cursor: pointer;
    color: #1D69CC;
}

.muse-input-caption {
    color: var(--muse-text-default-lighter);
    display: block;
    font-size: var(--muse-font-size-20);
    font-weight: var(--muse-font-weight-300);
}

.muse-input-caption>svg {
    fill: var(--muse-text-default);
    margin-right: var(--muse-space-30);
}

[class*=__muse-theme--dark] .muse-input-caption,
[data-theme=dark] .muse-input-caption {
    color: var(--muse-text-inverse);
}

.muse-input--error .muse-input-wrapper {
    border-color: var(--muse-border-error);
}

.muse-input--error .muse-input-caption svg {
    fill: var(--muse-text-error);
}

.muse-list,
.muse-list--reduced {
    --muse-list-text-color: var(--muse-text-default);
    color: var(--muse-list-text-color);
    margin-bottom: var(--muse-space-0);
    margin-left: var(--muse-space-0);
    margin-top: 0;
    padding-left: 0;
}

[class*=__muse-theme--dark] .muse-list,
[data-theme=dark] .muse-list,
[class*=__muse-theme--dark] .muse-list--reduced,
[data-theme=dark] .muse-list--reduced {
    --muse-list-text-color: var(--muse-text-inverse);
}

.muse-list:has(+ :is(ul, ol, .ul, .ol)),
.muse-list--reduced:has(+ :is(ul, ol, .ul, .ol)) {
    margin-bottom: var(--muse-space-100);
}

.muse-list li,
.muse-list--reduced li {
    margin: 0 0 var(--muse-space-50) var(--muse-space-50);
    line-height: var(--muse-space-70);
}

.muse-list li ul,
.muse-list li ol,
.muse-list--reduced li ul,
.muse-list--reduced li ol {
    margin-top: var(--muse-space-50);
    margin-bottom: var(--muse-space-0);
    margin-left: var(--muse-space-0);
    padding-left: 0;
}

.muse-list li:last-of-type,
.muse-list--reduced li:last-of-type {
    margin-bottom: var(--muse-space-0);
}

.muse-list ul,
.muse-list--reduced ul {
    list-style-type: revert;
}

.muse-list ol,
.muse-list .ol,
.muse-list--reduced ol,
.muse-list--reduced .ol {
    list-style-type: decimal;
    margin-bottom: var(--muse-space-0);
    margin-left: var(--muse-space-10);
    margin-top: 0;
    padding-left: 0;
}

.muse-list ol ol,
.muse-list .ol ol,
.muse-list--reduced ol ol,
.muse-list--reduced .ol ol {
    list-style-type: lower-alpha;
}

.muse-list ol ol ol,
.muse-list .ol ol ol,
.muse-list--reduced ol ol ol,
.muse-list--reduced .ol ol ol {
    list-style-type: lower-roman;
}

.muse-list ol ul,
.muse-list .ol ul,
.muse-list--reduced ol ul,
.muse-list--reduced .ol ul {
    list-style-type: disc;
}

@supports (-webkit-hyphens: none) {

    .muse-list ol,
    .muse-list .ol,
    .muse-list--reduced ol,
    .muse-list--reduced .ol {
        margin-left: var(--muse-space-20);
    }
}

.muse-list--reduced {
    font-size: var(--muse-font-size-20);
    line-height: var(--muse-line-height-20);
}

.muse-quantity-stepper {
    font-family: var(--muse-font-family-default);
}

.muse-quantity-stepper-label {
    color: var(--muse-text-default, var(--muse-text-neutral-default, #1b1c1d));
    display: block;
    font-size: var(--muse-font-size-40);
    font-weight: var(--muse-font-weight-300);
    margin-bottom: var(--muse-space-40);
}

.muse-quantity-stepper-inner-wrapper {
    display: flex;
    margin-bottom: var(--muse-space-40);
}

.muse-quantity-stepper-button {
    align-items: center;
    background: var(--muse-background-neutral);
    border: 1px solid var(--muse-border-default);
    display: flex;
    font-size: var(--muse-font-size-30);
    height: var(--muse-space-120);
    justify-content: center;
    width: var(--muse-space-120);
}

.muse-quantity-stepper-button>svg {
    fill: var(--muse-text-default);
}

.muse-quantity-stepper-button:first-of-type {
    border-radius: var(--muse-radius-30) 0 0 var(--muse-radius-30);
    border-right: transparent;
}

.muse-quantity-stepper-button:last-of-type {
    border-left: transparent;
    border-radius: 0 var(--muse-radius-30) var(--muse-radius-30) 0;
}

.muse-quantity-stepper-button:hover>svg {
    fill: var(--muse-text-accent);
}

.muse-quantity-stepper-button:focus-visible {
    border: 2px solid var(--muse-border-accent, #0070d2);
}

.muse-quantity-stepper-button:disabled {
    opacity: 0.4;
}

.muse-quantity-stepper-input {
    background: var(--muse-background-neutral);
    border: 1px solid var(--muse-border-default);
    font-size: var(--muse-font-size-30);
    height: var(--muse-space-120);
    line-height: var(--muse-line-height-30);
    max-width: 100px;
    padding: var(--muse-space-50) var(--muse-space-0) var(--muse-space-50) var(--muse-space-50);
    text-align: center;
}

.muse-quantity-stepper-input:focus-visible {
    border: 2px solid var(--muse-border-accent, #0070d2);
}

.muse-quantity-stepper-caption {
    color: var(--muse-text-default-lighter);
    display: block;
    font-size: var(--muse-font-size-20);
    font-weight: var(--muse-font-weight-300);
}

.muse-quantity-stepper-caption>svg {
    fill: var(--muse-text-default);
    margin-right: var(--muse-space-30);
}

.muse-quantity-stepper--error .muse-quantity-stepper-input {
    border-color: var(--muse-border-error);
}

.muse-quantity-stepper--error .muse-quantity-stepper-caption svg {
    fill: var(--muse-text-error);
}

.muse-radio-button-tile input,
.muse-radio input {
    appearance: none;
    background: #fff;
    border-radius: var(--muse-radius-circle);
    border: 1px solid var(--muse-border-default-darker);
    display: grid;
    height: var(--muse-space-60);
    margin: 0;
    padding: 0;
    place-content: center;
    width: var(--muse-space-60);
}

.muse-radio-button-tile input:focus-visible,
.muse-radio input:focus-visible {
    outline-offset: var(--muse-space-20);
    outline: 2px solid var(--muse-border-accent);
}

.muse-radio-button-tile input::before,
.muse-radio input::before {
    content: "";
    border-radius: var(--muse-radius-circle);
    box-shadow: inset 1em 1em var(--muse-background-accent);
    height: var(--muse-space-40);
    transform: scale(0);
    width: var(--muse-space-40);
}

.muse-radio-button-tile input:checked,
.muse-radio input:checked {
    border-color: var(--muse-border-accent);
}

.muse-radio-button-tile input:checked::before,
.muse-radio input:checked::before {
    transform: scale(1);
}

[class*=__muse-theme--dark] .muse-radio-button-tile input,
.muse-radio-button-tile [class*=__muse-theme--dark] input,
[class*=__muse-theme--dark] .muse-radio input,
.muse-radio [class*=__muse-theme--dark] input,
[data-theme=dark] .muse-radio-button-tile input,
.muse-radio-button-tile [data-theme=dark] input,
[data-theme=dark] .muse-radio input,
.muse-radio [data-theme=dark] input {
    background: var(--muse-background-emphasis);
}

[class*=__muse-theme--dark] .muse-radio-button-tile input:focus-visible,
.muse-radio-button-tile [class*=__muse-theme--dark] input:focus-visible,
[class*=__muse-theme--dark] .muse-radio input:focus-visible,
.muse-radio [class*=__muse-theme--dark] input:focus-visible,
[data-theme=dark] .muse-radio-button-tile input:focus-visible,
.muse-radio-button-tile [data-theme=dark] input:focus-visible,
[data-theme=dark] .muse-radio input:focus-visible,
.muse-radio [data-theme=dark] input:focus-visible {
    box-shadow: 0 0 0 4px var(--muse-border-emphasis), 0 0 0 6px var(--muse-border-inverse), 0 0 0 8px var(--muse-border-accent);
    outline: none;
}

.muse-radio {
    font-family: var(--muse-font-family-default);
    max-width: fit-content;
}

.muse-radio-wrapper {
    align-items: flex-start;
    display: flex;
    gap: var(--muse-space-40);
}

.muse-radio-wrapper:hover label {
    color: var(--muse-text-accent-darker);
}

.muse-radio-wrapper:hover input {
    border-color: var(--muse-border-accent-darker);
}

[class*=__muse-theme--dark] .muse-radio-wrapper:hover label,
[data-theme=dark] .muse-radio-wrapper:hover label {
    color: var(--muse-text-accent-lighter);
}

[class*=__muse-theme--dark] .muse-radio-wrapper:hover input,
[data-theme=dark] .muse-radio-wrapper:hover input {
    border-color: var(--muse-border-accent-lighter);
}

.muse-radio-label {
    color: var(--muse-text-default);
    font-size: var(--muse-font-size-30);
    font-weight: var(--muse-font-weight-300);
}

.muse-radio-label span {
    display: block;
    margin-bottom: var(--muse-space-30);
}

[class*=__muse-theme--dark] .muse-radio-label,
[data-theme=dark] .muse-radio-label {
    color: var(--muse-text-inverse);
}

.muse-radio-caption {
    color: var(--muse-text-default-lighter);
    font-size: var(--muse-font-size-20);
}

[class*=__muse-theme--dark] .muse-radio-caption,
[data-theme=dark] .muse-radio-caption {
    color: var(--muse-text-inverse);
}

.muse-radio--error input,
.muse-radio-button-tile--error input {
    border-color: var(--muse-border-error);
}

.muse-radio--error .muse-radio-button-tile-wrapper,
.muse-radio-button-tile--error .muse-radio-button-tile-wrapper {
    border: 1px solid var(--muse-border-error);
}

.muse-radio-button-tile {
    font-family: var(--muse-font-family-default);
    max-width: fit-content;
}

.muse-radio-button-tile-wrapper {
    display: flex;
    border: 1px solid var(--muse-border-default);
    border-radius: var(--muse-radius-30);
    align-items: center;
}

.muse-radio-button-tile-wrapper:has(input:checked) {
    border-color: var(--muse-border-accent);
}

.muse-radio-button-tile-wrapper:has(input:focus-visible) {
    outline-offset: 4px;
    outline: 2px solid var(--muse-border-accent);
}

.muse-radio-button-tile-wrapper:hover {
    border-color: var(--muse-border-accent-darker);
    cursor: pointer;
}

.muse-radio-button-tile .input-container {
    padding: var(--muse-space-50);
}

.muse-radio-button-tile input:focus-within {
    outline: none;
}

.muse-radio-button-tile-content {
    padding: var(--muse-space-50);
    border-left: 1px solid var(--muse-border-default);
    color: var(--muse-text-default);
    display: flex;
    align-items: center;
    gap: var(--muse-space-70);
}

[class*=__muse-theme--dark] .muse-radio-button-tile-content,
[data-theme=dark] .muse-radio-button-tile-content {
    color: var(--muse-text-inverse);
}

.muse-radio-button-tile--stacked .muse-radio-button-tile-content {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--muse-space-20);
}

.muse-radio-button-tile-content .meta-header {
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.8rem;
}

.muse-radio-button-tile-content .meta-caption {
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 2rem;
}

.muse-radio-button-tile-badge {
    border-radius: var(--muse-radius-10);
    border: 1px solid var(--muse-border-default-darker);
    color: var(--muse-text-default-lighter);
    font-size: var(--muse-font-size-10);
    margin-bottom: var(--muse-space-40);
    padding: var(--muse-space-20) var(--muse-space-30);
    text-align: center;
    width: fit-content;
}

[class*=__muse-theme--dark] .muse-radio-button-tile-badge,
[data-theme=dark] .muse-radio-button-tile-badge {
    color: var(--muse-text-inverse);
    border: 1px solid var(--muse-border-inverse);
}

.muse-radio-button-tile-label {
    font-size: var(--muse-font-size-30);
    color: var(--muse-text-default);
    font-weight: var(--muse-font-weight-500);
}

[class*=__muse-theme--dark] .muse-radio-button-tile-label,
[data-theme=dark] .muse-radio-button-tile-label {
    color: var(--muse-text-inverse);
}

.muse-select {
    font-family: var(--muse-font-family-default);
}

.muse-select-wrapper {
    border-radius: var(--muse-radius-30);
    border: 1px solid var(--muse-border-default);
    font-size: var(--muse-font-size-30);
    margin-bottom: var(--muse-space-40);
    padding: 0 var(--muse-space-60) var(--muse-space-40) var(--muse-space-60);
}

.muse-select-wrapper:focus-within {
    /* border-radius: 12px;
    outline: 2px solid var(--muse-border-accent);
    outline-offset: 6px; */
}

[class*=__muse-theme--dark] .muse-select-wrapper,
[data-theme=dark] .muse-select-wrapper {
    border: 1px solid var(--muse-border-inverse);
}

.muse-select-label {
    background: var(--muse-background-neutral);
    color: var(--muse-text-default-lighter);
    display: block;
    font-size: var(--muse-font-size-20);
    font-weight: var(--muse-font-weight-300);
    height: var(--muse-space-60);
    line-height: var(--muse-size-60);
    margin-top: -10px;
    padding: 0 var(--muse-space-20);
    position: relative;
    width: fit-content;
    z-index: 1;
}

[class*=__muse-theme--dark] .muse-select-label,
[data-theme=dark] .muse-select-label {
    background: var(--muse-background-emphasis);
    color: var(--muse-text-inverse);
}

.muse-select-field {
    appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE1IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjQ5OTk3IDcuOTk0MTRDNy4zNjc0MyA3Ljk5NDI2IDcuMjQwMjggNy45NDE3NSA3LjE0NjQ1IDcuODQ4MTRMMC43OTI5NjkgMS41MDQzOUwxLjQ5ODk3IDAuNzk2ODg0TDcuNDk4OTcgNi43ODcxMUwxMy40OTkgMC43NzczNDRMMTQuMjA3IDEuNDgzODlMNy44NTM0OSA3Ljg0NzE3QzcuODA3MjEgNy44OTM4MyA3Ljc1MjEzIDcuOTMwODUgNy42OTE0NSA3Ljk1NjA3QzcuNjMwNzcgNy45ODEzIDcuNTY1NjkgNy45OTQyNCA3LjQ5OTk3IDcuOTk0MTRaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K");
    background-position: right;
    background-repeat: no-repeat;
    border: 0;
    font-weight: var(--muse-font-weight-500);
    line-height: var(--muse-size-50);
    margin-top: var(--muse-space-10);
    width: 100%;
}

.muse-select-field::placeholder {
    color: var(--text-default-lighter, #606162);
}

.muse-select-field:focus {
    outline: none;
}

[class*=__muse-theme--dark] .muse-select-field,
[data-theme=dark] .muse-select-field {
    color: var(--muse-text-inverse);
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAICAYAAAAm06XyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABGSURBVHgBpY7BDQAgCAMZCSdyY91AR0H8kQgNhib9kN4FompEZGo5sbtt2mGPrN1IYMD17JAAgkiQAiNBGnQE/QuMPvByANpkhzpmw4b4AAAAAElFTkSuQmCC");
}

.muse-select-caption {
    color: var(--muse-text-default-lighter);
    display: block;
    font-size: var(--muse-font-size-20);
    font-weight: var(--muse-font-weight-300);
}

.muse-select-caption>svg {
    fill: var(--muse-text-default);
    margin-right: var(--muse-space-30);
}

[class*=__muse-theme--dark] .muse-select-caption,
[data-theme=dark] .muse-select-caption {
    color: var(--muse-text-inverse);
}

.muse-select--error .muse-select-wrapper {
    border-color: var(--muse-border-error);
}

.muse-select--error .muse-select-caption svg {
    fill: var(--muse-text-error);
}

.muse-table {
    font-family: var(--muse-font-family-default);
    overflow: auto hidden;
    width: 100%;
}

.muse-table table {
    border-collapse: collapse;
    border: 1px solid var(--muse-border-default);
    table-layout: auto;
}

.muse-table table th,
.muse-table table td {
    border: 1px solid var(--muse-border-default);
    padding: var(--muse-space-50) var(--muse-space-70);
    text-align: left;
}

.muse-table table th {
    background: var(--muse-background-neutral-darker);
    font-size: var(--muse-font-size-30);
    font-weight: var(--muse-font-weight-500);
}

.muse-table table th[scope=col] {
    width: auto;
}

.muse-table table td {
    font-size: var(--muse-font-size-20);
    font-weight: var(--muse-font-weight-300);
}

.muse-table-full-width table {
    width: 100%;
}

.muse-table-fixed table {
    table-layout: fixed;
    width: 100%;
}

.muse-table-trunc-text td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.muse-table-dense table th,
.muse-table-dense table td {
    padding: var(--muse-space-30) var(--muse-space-50);
}

.muse-table-striped tr:nth-of-type(even) {
    background: var(--muse-background-neutral-dark);
}

.muse-disabled-button {
    background-color: #ccc;
    cursor: not-allowed !important;
}

.muse-disabled-button:hover {
    background-color: #ccc;
    cursor: not-allowed !important;
}