.muse-input-field:focus {
    outline: none; /* Remove default focus outline from input */
}

.keyboard-focused .muse-input-wrapper:focus-within {
    background-color: transparent !important;
    border: 2px solid black !important;
}

.keyboard-focused .muse-select-wrapper:focus-within {
    background-color: transparent !important;
    border: 2px solid black !important;
    outline: none;
}

.captchaV2Holder {
    margin-top: 20px;
}
