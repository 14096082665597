/*
                loggedout.css
                $Revision: 1.2.0.0 $
                . used by logged out success and partial success pages only
                (c) 1992-2015 Cisco Systems, Inc. All rights reserved.
                Terms and Conditions: http://cisco.com/en/US/swassets/sw293/sitewide_important_notices.html
                */
#fw-mb-w1 {
    width: 98%;
}

#fw-mb-w1 a {
    text-decoration: none;
    color: #007FC5;
}

#fw-mb #popup-wrapper {
    padding-top: 10px;
}

#fw-mb #popup-content {
    border: none;
    margin-bottom: 110px;
}

#fw-mb #popup-content-padding {
    margin: 15px 0;
}

#logoutsuccess {
    background-color: #f5f5f5;
    padding: 40px 30px;
}

#logoutsuccess p {
    margin: 0 0 10px 0;
    color: #525252;
    font-size: 14px;
}

#logoutsuccess .title {
    font-size: 26px;
    margin-bottom: 20px;
    color: #444;
}

#logoutsuccess .message {
    font-size: 16px;
    margin-bottom: 30px;
}

#fw-mb #framework-footer {
    border-top: 1px #eaeaea solid;
}

@media only screen and (min-width: 48em) {
    #fw-mb-w1 {
        width: 80%;
    }
}

@media only screen and (min-width: 64em) {
    #fw-mb-w1 {
        width: 60%;
    }
}


body.cdc-fw {
    background: none;
}

#fw-mb {
    background: none;
    padding: 0 15px;
}



#fw-mb #popup-wrapper {
    position: relative;
}

#fw-mb #fw-banner {
    color: #b4b4b4;
    padding-right: 0;
}

#fw-mb #fw-banner a:link,
#fw-mb #fw-banner a:visited,
#fw-mb #fw-banner a:active {
    color: #007fc5;
}

#fw-mb #fw-banner a:hover {
    text-decoration: underline;
}

#fw-mb #popup-banner {
    display: table;
    position: relative;
    height: 64px;
    min-height: 64px;
    width: 100%;
}

#ie7 #fw-mb #popup-banner {
    height: auto;
    zoom: 1;
}

#fw-mb #popup-banner #popup-logo {
    position: absolute;
    top: 6px;
    width: 60px;
    height: 50px;
    /* background: transparent url(/web/fw/i/logo_60x50.jpg) 0 0 no-repeat;*/
}

#fw-mb #popup-banner #popup-logo a {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0;
    color: transparent !important;
}

#ie7 #fw-mb #popup-banner #popup-logo a {
    text-indent: -1000px;
    overflow: hidden;
}

#fw-mb #popup-banner h1,
#fw-mb #popup-banner h2 {
    display: table-cell;
    vertical-align: middle;
    padding: 15px 0 3px 80px;
    font-size: 18px;
    font-weight: normal;
    color: #444;
}

#ie7 #fw-mb #popup-banner h1 {
    padding-top: 28px;
    margin-bottom: 0;
}

#fw-mb #popup-content {
    border-width: 1px 0;
    display: table;
    width: 100%;
}

#fw-mb #popup-content-padding {
    margin: 30px 0;
}

#fw-mb-w1 #framework-footer {
    margin: 0;
    border-top: none;
}

#fw-mb #framework-footer {
    padding-left: 0;
    color: #b4b4b4;
}

#fw-mb #fw-footer a:link,
#fw-mb #fw-footer a:visited,
#fw-mb #fw-footer a:active {
    color: #007fc5;
}

#fw-mb #footer-legal {
    position: relative;
    left: -2px;
    zoom: 1;
}

#fw-mb-w1 {
    margin: auto;
    position: relative
}
#fw-mb #popup-wrapper {
    position: relative;
}
#fw-mb #fw-banner {
    color: #b4b4b4;
    padding-right: 0;
}
#fw-mbsk {
    background: #000;
    position: absolute;
    top: -5000px;
    left: 0;
    right: 0;
    z-index: 1050;
    font-size: 11px
}
#fw-mbsk p {
    margin: 0
}
#fw-mb #fw-banner a:link,#fw-mb #fw-banner a:visited,#fw-mb #fw-banner a:active {
    color: #007fc5;
}
#fw-banner a:link,#fw-banner a:visited,#fw-banner a:active {
    color: #fff;
    text-decoration: none
}
#fw-mbsk a {
    display: block;
    padding: 3px 10px;
    float: left
}

#fw-mbsk a+a {
    border-left: 1px solid #4d4d4d;
    margin-left: 1px
}
#fw-mb #popup-banner #popup-logo {
    position: absolute;
    top: 6px;
    width: 60px;
    height: 50px;
    background: transparent url(../../assets/images/logo_60x50.jpg) 0 0 no-repeat;
}