.tooltip {
    position: relative;
    /*display: inline-block;*/
}

.tooltip-icon {
    left: -98px !important;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 360px;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    border: .5px solid #d6d6d6;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: 130%;
    left: -15%;
    margin-left: -75px;
    font-size: 13px;
    transition: opacity 0.3s ease-out;
    color: #1B1C1D;
    text-align: center;
    cursor: text;
    top: calc(100% + 10px);
    word-wrap: break-word;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #d5d5d5 transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;

}

.tooltip:focus-within .tooltiptext {
    visibility: visible;
}

.tooltip:focus .tooltiptext {
    visibility: visible;
}

#partnerSupport .tooltiptext {
    left: -15%;
}

/*.multilineResources {
    top: 78% !important;
}*/
#exclamationCircleTooltip4 {
    left: -88px !important;
}