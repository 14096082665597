.results-list {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #ddd;
  border-radius: 10px;
  margin-top: 0.2rem;
  max-height: 300px;
  overflow-y: auto;
}
.searchBoxLabelFaq{
  position: relative;
  left: 3%;
  width: fit-content;
}
.searchBoxLabelResource{
  position: relative;
  left: 3%;
  width: fit-content;
  top: 5px;
}