.padding-0 {
    padding: 0;
}

.faqTitle {
    margin-bottom: 31px;
}

.faqBorder {
    border: 1px solid #EBEBEB;
    padding: 1em;
}

.most-popular-question {
    font-size: 16px !important;
    margin-bottom: 22px;
}

.muse-accordion-body p {
    margin: 16px;
}

#main-enquiry {
    width: 100%;
}

.editFaqSectionHeading {
    margin: 15px 0;
    text-align: center;
}

.addNewQuestionButton {
    text-align: right;
    margin-bottom: 15px;
}

.fullWidth {
    width: 100% !important;
}

.fullBorder {
    border: 1px solid rgb(204, 204, 204)
}

.faqTextControlsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faqTextControlsWrapper input {
    padding: 0;
}

.faqHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faqControls {
    width: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.faqControls svg {
    margin-left: 10px;
}

.editQuestionButtonHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.editQuestionButtonHolder button {
    margin-left: 10px;
}

.muse-accordion-title {
    justify-content: space-between;
}

.faqEditModalFooterButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 25px;
}

.faqEditModalFooterButtons button {
    margin-left: 10px;
}

.editFaqError {
    color: red;
    margin-top: -15px;
}

.editQuestionButtonHolder input {
    width: auto;
}

.editQuestionButtonHolder .muse-checkbox-label {
    margin-left: 5px;
}