.search-result {
  padding: 10px 20px 10px 35px;
  display: flex;
}

.search-result:hover {
  --muse-text-accent: var(--muse-color-blue-20);
}

.search-result .selected-item {
  outline: #000000 auto 2px !important;
}

.item-unselected {
  outline: none !important;
}

.results-list a {
  text-decoration: none !important;
  color: inherit !important; /* Use the color of the parent element */
  cursor: pointer; /* Change cursor to pointer */
  background: none; /* Remove any background */
  border: none; /* Remove any border */
  padding: 0; /* Remove padding */
  font: inherit; /* Inherit font properties */
  outline: none !important;
  display: block !important;
  width: 100% !important;
}

/* .results-list a[href]:focus-visible{
  outline-offset: 4px !important;
  box-shadow:0 0 0 2px #000000 !important;
  border-radius: 1px !important
} */