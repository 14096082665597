.muse-accordion a:focus {
  background-color: transparent !important;
  outline: 2px solid black !important;
}

.muse-accordion a:focus-visible{
    background-color: transparent !important;
    outline: 2px solid black !important;
}

.muse-accordion a:focus-within{
    background-color: transparent !important;
    outline: 2px solid black !important;
}
