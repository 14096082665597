.input-wrapper {
  width: 100%;
  height: 4.5rem;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  padding: 0 15px;
  /* box-shadow: 0px 0px 8px #ddd; */
  background-color: white;
  display: flex;
  align-items: center;
}

input {
  width: 100%;
  padding: 0px 0px 0px 13px;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: inherit;
  font-family: inherit;
  color: rgb(33, 33, 33);
}

input:focus {
  outline: none;
}

#search-icon {
  margin: 0px 0px 0px 16px;
  fill: grey;
}

.input-wrapper:focus-within {
  outline-offset: 4px !important;
  box-shadow: 0 0 0 1px var(--muse-color-blue-20) !important;
  border-radius: 8px !important /* Change border color and width */
}

@media (width >=600px) {
  .input-wrapper, .results-list {
    width: 95% !important;
    position: relative;
    left: 31px;
  }
}

@media only screen and (max-width: 1024px) {
  .input-wrapper, .results-list {
    width: 100%;
    height: 4.5rem;
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    padding: 0 15px;
    background-color: white;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
  }
}

@media only screen 
  and (min-width: 1367px) 
  and (max-width: 1536px) { 
    .input-wrapper, .results-list {
      width: 95% !important;
      position: relative;
      left: 28px;
    }
}

@media only screen 
  and (min-width: 991px) 
  and (max-width: 1366px) { 
    .input-wrapper, .results-list {
      width: 87% !important;
      position: relative;
      left: 55px;
    }
}

@media only screen 
  and (min-width: 991px) 
  and (max-width: 1250px) { 
    .input-wrapper, .results-list {
      width: 87% !important;
      position: relative;
      left: 52px;
    }
}


/* 125% */
@media only screen 
  and (min-width: 991px) 
  and (max-width: 1100px) { 
    .input-wrapper, .results-list {
      width: 87% !important;
      position: relative;
      left: 43px;
    }
}